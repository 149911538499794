import React from 'react';
import PropTypes from 'prop-types';

// import { Container } from './styles';

const UsersReactivate = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 50 46" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path d="M20 22.8571C23.031 22.8571 25.9379 21.6531 28.0812 19.5098C30.2245 17.3665 31.4286 14.4596 31.4286 11.4286C31.4286 8.39753 30.2245 5.49062 28.0812 3.34735C25.9379 1.20408 23.031 0 20 0C16.969 0 14.0621 1.20408 11.9188 3.34735C9.77551 5.49062 8.57143 8.39753 8.57143 11.4286C8.57143 14.4596 9.77551 17.3665 11.9188 19.5098C14.0621 21.6531 16.969 22.8571 20 22.8571ZM15.9196 27.1429C7.125 27.1429 0 34.2679 0 43.0625C0 44.5268 1.1875 45.7143 2.65179 45.7143H37.3482C38.8125 45.7143 40 44.5268 40 43.0625C31.4286 37 31.4286 34.5 24.0804 27.1429H15.9196Z" />
    <path d="M33.7133 24.1109C34.0342 23.2023 34.5551 22.348 35.2886 21.6187C37.8933 19.014 42.115 19.014 44.7197 21.6187L45.4324 22.3355H44.0029C43.2653 22.3355 42.6693 22.9314 42.6693 23.6691C42.6693 24.4068 43.2653 25.0027 44.0029 25.0027H48.6497H48.6664C49.404 25.0027 50 24.4068 50 23.6691V19.0015C50 18.2638 49.404 17.6678 48.6664 17.6678C47.9287 17.6678 47.3328 18.2638 47.3328 19.0015V20.4684L46.6035 19.7349C42.9569 16.0884 37.0473 16.0884 33.4007 19.7349C32.3838 20.7518 31.6503 21.9479 31.2002 23.2273C30.9544 23.9233 31.3211 24.6818 32.0129 24.9277C32.7047 25.1736 33.4674 24.8068 33.7133 24.115V24.1109ZM30.9585 27.7241C30.7502 27.7866 30.5501 27.8991 30.3876 28.0658C30.2209 28.2325 30.1084 28.4326 30.05 28.6493C30.0375 28.6993 30.025 28.7535 30.0167 28.8077C30.0042 28.8785 30 28.9494 30 29.0202V33.6712C30 34.4088 30.596 35.0048 31.3336 35.0048C32.0713 35.0048 32.6672 34.4088 32.6672 33.6712V32.2084L33.4007 32.9377C37.0473 36.5801 42.9569 36.5801 46.5993 32.9377C47.6162 31.9208 48.3538 30.7247 48.8039 29.4453C49.0498 28.7493 48.6831 27.9908 47.9912 27.7449C47.2994 27.4991 46.5368 27.8658 46.2909 28.5576C45.97 29.4661 45.4491 30.3205 44.7156 31.0498C42.1109 33.6545 37.8891 33.6545 35.2844 31.0498L35.2803 31.0456L34.5676 30.3372H36.0013C36.7389 30.3372 37.3349 29.7412 37.3349 29.0035C37.3349 28.2659 36.7389 27.6699 36.0013 27.6699H31.3503C31.2836 27.6699 31.2169 27.6741 31.1502 27.6824C31.0836 27.6908 31.021 27.7033 30.9585 27.7241Z" />
  </svg>
);

UsersReactivate.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

UsersReactivate.defaultProps = {
  width: '20px',
  height: '20px',
  fill: 'none',
};

export default UsersReactivate;
