import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { AlertContext } from '../../../context/AlertProvider';
import { StyledAnchor } from './styled/Anchor.styled';

const Anchor = ({ cellContent, props }) => {
  const systemMap = {
    'legalone-analytics': 'Legal One Analytics',
    'benner-metrics': 'Benner Metrics',
    legalmetrics: 'Legal Metrics',
  };
  const { setAlertConfig } = useContext(AlertContext);
  const handleAlert = (e) => {
    e.preventDefault();
    setAlertConfig({
      text: `Você clicou em um link externo ao ${systemMap[process.env.REACT_APP_FIREBASE_PROJECT_LABEL]}.`,
      child: 'Você será redirecionado para a URL correspondente. Tem certeza que deseja continuar?',
      type: 'warning',
      confirmFunction: () => {
        window.open(cellContent, '_blank');
      },
      withFunction: true,
      withoutConfirm: true,
    });
  };
  return (
    <StyledAnchor
      onClick={handleAlert}
      href={cellContent}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    >
      {cellContent}
    </StyledAnchor>
  );
};

Anchor.propTypes = {
  cellContent: PropTypes.string.isRequired,
  props: PropTypes.shape({}),
};

Anchor.defaultProps = {
  props: {},
};

export default Anchor;
