import React from 'react';

import { AlertText } from './styled/ShareList.styled';

const PublicLinkAlert = () => (
  <>
    <div>
      <AlertText>
        <b>
          Atenção 1:
        </b>
        {' '}
        Alterações realizadas no dashboard não são atualizadas automaticamente no
        link público. Para que as alterações tenham efeito, é necessário que o link
        seja atualizado manualmente.
      </AlertText>
    </div>
    <div>
      <AlertText>
        <b>
          Atenção 2:
        </b>
        {' '}
        Caso o seu dashboard possua subtabelas ou gráficos com subgráficos, as
        subtabelas não podem ser renderizadas e os subgráficos não podem ser acessados
        no link público.
      </AlertText>
    </div>
  </>
);

export default PublicLinkAlert;
