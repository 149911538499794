/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { format } from 'date-fns';
import * as ops from '../../../utils/models/customColumnsOperations';
import uuidv4 from '../../../juristec-ui/utils/functions/randomUUID';
import { verifyColumnName } from '../../../juristec-ui/utils/validators/inputTextValidators';

import StepProgress from '../../../juristec-ui/core/StepProgress';
import { FirstStep, SecondStep } from './Steps';

const inputTypes = {
  COLUMN: { label: 'Coluna', value: 'COLUMN', id: 'COLUMN' },
  TODAY: { label: 'Data de "Hoje"', value: 'TODAY', id: 'TODAY' },
  DATE: { label: 'Data fixa', value: 'DATE', id: 'DATE' },
  NUMBER: { label: 'Número fixo', value: 'NUMBER', id: 'NUMBER' },
};

const ManageCustomColumns = ({
  columns, custom, editCustom, genPreview, submit, hide,
}) => {
  const [previewCheck, setPreviewCheck] = useState(false);
  const [validColumns, setValidColumns] = useState(null);
  const [opOpts, setOpOpts] = useState([]);
  const [columnOpts, setColumnOpts] = useState([]);
  const [selectedOp, setSelectedOp] = useState(null);
  const [tmpInputTypeOpts, setTmpInputTypeOpts] = useState([]);
  const [tmpInputType, setTmpInputType] = useState(null);
  const [tmpInputValue, setTmpInputValue] = useState({
    id: '', label: '', value: '', type: '', origin: '',
  });
  const [selectedInputs, setSelectedInputs] = useState(new Map());
  const [columnName, setColumnName] = useState({ value: '', error: true, errorMsg: '' });

  const handleNewName = (value) => {
    const msg = verifyColumnName(value, validColumns ?? {});
    setColumnName({
      value,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const resetTmpInput = (type, operation, vColumns) => {
    switch (type?.value) {
      case 'COLUMN': {
        const colList = Object.entries(vColumns ?? validColumns).reduce((aux, [k, v]) => {
          if (operation.strictColumnType === null || operation.strictColumnType === v) {
            aux.push({
              label: k, value: k, id: k, type: v,
            });
          }
          return aux;
        }, []);
        setColumnOpts(colList);
        setTmpInputValue(colList[0]);
        break;
      }
      case 'TODAY':
        setTmpInputValue({ label: 'HOJE', type: 'date', value: new Date() });
        break;
      case 'DATE': {
        const today = new Date();
        setTmpInputValue({ label: format(today, 'dd/MM/yyyy'), type: 'date', value: today });
        break;
      }
      case 'NUMBER':
        setTmpInputValue({ label: '0', type: 'float', value: 0 });
        break;
      default:
        break;
    }
  };

  const handleSelectedOp = (op, resetInputs = true, nextInputType = 0, vColumns) => {
    let inpList = [];
    if (op.strictOrder && nextInputType < op.maxInputs) {
      inpList = op.allowedInputs[nextInputType].map((a) => inputTypes[a]);
    } else {
      inpList = op.allowedInputs[0].map((a) => inputTypes[a]);
    }
    setSelectedOp(op);
    setTmpInputTypeOpts(inpList);
    setTmpInputType(inpList[0]);
    resetTmpInput(inpList[0], op, vColumns);
    if (resetInputs) setSelectedInputs(new Map());
  };

  useEffect(() => {
    let vColumns = null;
    if (columns) {
      const removed = [];
      const fromCustom = custom.reduce((aux, cc) => {
        if (editCustom?.name === cc.name) return aux;
        if (cc.operator === 'REMOVE') removed.push(cc.name);
        else aux[cc.name] = cc.operator.type;
        return aux;
      }, {});
      vColumns = Object.entries(columns).reduce((aux, [k, v]) => {
        if (removed.includes(k)) return aux;
        if (editCustom?.name === k) return aux;
        if (k in fromCustom) return aux;
        aux[k] = v;
        return aux;
      }, fromCustom);
      setValidColumns(vColumns);
    }
    const opList = Object.values(ops);
    if (editCustom) {
      setOpOpts(opList.filter((op) => op.type === editCustom.operator.type));
      handleSelectedOp(editCustom.operator, false, editCustom.operator.maxInputs - 1, vColumns);
      setSelectedInputs(new Map(editCustom.inputs.map((editI) => [editI.id, editI])));
      handleNewName(editCustom.name);
    } else {
      setOpOpts(opList);
      handleSelectedOp(opList[0], false, 0, vColumns);
    }
  }, []);

  const handleSelectedInputType = (selected) => {
    resetTmpInput(selected, selectedOp);
    setTmpInputType(selected);
  };

  const handleSelectInput = (selected) => {
    setTmpInputValue(selected);
  };

  const handleStaticInput = (value) => {
    setTmpInputValue((o) => {
      const auxObj = { ...o };
      auxObj.value = value;
      if (tmpInputType.value === 'DATE') auxObj.label = format(value, 'dd/MM/yyyy');
      else if (tmpInputType.value === 'NUMBER') auxObj.label = value.toString().replace('.', ',');
      return auxObj;
    });
  };

  const addInput = () => {
    setSelectedInputs((o) => {
      const inp = tmpInputValue;
      const id = uuidv4();
      let { value } = inp;
      if (tmpInputType.value === 'TODAY') {
        value = 'TODAY';
      } else if (inp.value instanceof Date) {
        value = format(inp.value, 'yyyy-MM-dd');
      }
      const inpObj = {
        ...inp,
        id,
        origin: tmpInputType.value,
        value,
      };
      const newMap = new Map(o);
      newMap.set(id, inpObj);
      handleSelectedOp(selectedOp, false, newMap.size);
      return newMap;
    });
  };

  const removeInput = (id) => {
    setSelectedInputs((o) => {
      const newMap = new Map(o);
      newMap.delete(id);
      handleSelectedOp(selectedOp, false, newMap.size);
      return newMap;
    });
  };

  const checkOp = () => (
    selectedOp?.value?.length > 0
    && selectedInputs.size >= selectedOp.minInputs
    && selectedInputs.size <= selectedOp.maxInputs
    && !columnName.error
    && columnName.value.length > 0
  );

  const firstStep = {
    label: 'Criação',
    validation: checkOp,
    content: (
      <FirstStep
        opOpts={opOpts}
        editMode={Boolean(editCustom)}
        tmpInputTypeOpts={tmpInputTypeOpts}
        columnOpts={columnOpts}
        selectedOpState={[selectedOp, handleSelectedOp]}
        tmpInputTypeState={[tmpInputType, handleSelectedInputType]}
        tmpInputValueState={[tmpInputValue, handleSelectInput, handleStaticInput]}
        selectedInputsState={[selectedInputs, addInput, removeInput]}
        columnNameState={[columnName, handleNewName]}
      />
    ),
  };

  const checkPreview = () => previewCheck;

  const secondStep = {
    label: 'Prévia',
    validation: checkPreview,
    content: (
      <SecondStep
        custom={custom}
        newCColumn={{
          inputs: [...selectedInputs.values()],
          operator: selectedOp,
          name: columnName?.value ?? '',
        }}
        genPreview={genPreview}
        previewCheck={setPreviewCheck}
      />
    ),
  };

  const handleSubmit = () => {
    submit({
      inputs: [...selectedInputs.values()],
      operator: selectedOp,
      name: columnName?.value ?? '',
    });
    hide();
  };

  return (
    <>
      <StepProgress
        steps={[firstStep, secondStep]}
        hide={hide}
        complete={handleSubmit}
        markersWidth="large"
        disableUntilValid
      />
    </>
  );
};

ManageCustomColumns.propTypes = {
  columns: PropTypes.objectOf(PropTypes.string),
  custom: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    operator: PropTypes.oneOfType([
      PropTypes.shape({
        value: PropTypes.string,
      }),
      PropTypes.string,
    ]),
    inputs: PropTypes.arrayOf(PropTypes.shape({})),
  })),
  editCustom: PropTypes.shape({
    name: PropTypes.string,
    operator: PropTypes.shape({
      type: PropTypes.string,
      maxInputs: PropTypes.number,
      value: PropTypes.string,
    }),
    inputs: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  genPreview: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
};

ManageCustomColumns.defaultProps = {
  columns: {},
  custom: [],
  editCustom: null,
};

export default ManageCustomColumns;
