import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Key, NotFound, Restore } from '../../../juristec-ui/icons';

import {
  MainContainer,
  ActionContainer,
  ActionButton,
} from './styled/PublicBlocker.styled';

const REASONTITLEMAP = {
  notFound: 'Erro 404',
  passwordProtected: 'Verificação Necessária',
  linkExpired: 'Link Expirado',
  error: 'Erro Inesperado',
};

const REASONTEXTMAP = {
  notFound: 'O dashboard que você está tentando acessar não foi encontrado ou está desabilitado.',
  passwordProtected: 'O dashboard que você está tentando acessar está protegido por senha.',
  linkExpired: 'O link que você está tentando acessar expirou.',
  error: 'Ocorreu um erro ao tentar acessar o dashboard.',
};

const PublicBlocker = ({
  reason, action,
}) => {
  const history = useHistory();

  const handleHome = () => {
    history.push('/home');
  };

  return (
    <MainContainer>
      <h1>{REASONTITLEMAP[reason] ?? REASONTITLEMAP.notFound}</h1>
      <span>
        {REASONTEXTMAP[reason] ?? REASONTEXTMAP.notFound}
      </span>
      <NotFound />
      <ActionContainer>
        <ActionButton
          onClick={handleHome}
          className="action-button"
          textTransform="none"
        >
          Voltar para o início
          <Restore />
        </ActionButton>
        {reason === 'passwordProtected' && (
          <ActionButton
            onClick={action}
            className="action-button"
            style={{ marginLeft: '10px' }}
            textTransform="none"
          >
            Acessar por senha
            <Key />
          </ActionButton>
        )}
      </ActionContainer>
    </MainContainer>
  );
};

PublicBlocker.propTypes = {
  reason: PropTypes.oneOf([
    'notFound', 'passwordProtected', 'linkExpired', 'error',
  ]),
  action: PropTypes.func,
};

PublicBlocker.defaultProps = {
  reason: 'notFound',
  action: () => {},
};

export default PublicBlocker;
