import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  DashboardName,
  OwnerInformation,
  InfoContainer,
  DevelopmentCredits,
} from './styled/PublicKpiToolbar.styled';

import { formatDateTime } from '../../../juristec-ui/utils/functions/lab';

import JuristecLogo from '../../../juristec-ui/icons/JuristecLogo';

function PublicKpiToolbar({ dashboardDoc }) {
  return (
    <Container>
      <InfoContainer>
        <OwnerInformation>
          <div>
            <span className="info-title">Criado por:</span>
            <span className="info-description">
              {' '}
              {dashboardDoc?.displayName}
            </span>
          </div>
          {dashboardDoc?.generatedAt && (
            <div>
              <span className="info-title">Gerado em:</span>
              <span className="info-description">
                {' '}
                {formatDateTime(dashboardDoc?.generatedAt, { time: 'half' })}
              </span>
            </div>
          )}
          {dashboardDoc?.expiresAt && (
            <div>
              <span className="info-title">Expira em:</span>
              <span className="info-description">
                {' '}
                {dashboardDoc.expiresAt !== 'NEVER'
                  ? formatDateTime(dashboardDoc?.expiresAt, { time: 'half' })
                  : 'Não expira' }
              </span>
            </div>
          )}
        </OwnerInformation>
        <DashboardName>
          {dashboardDoc?.name || ''}
        </DashboardName>
        <DevelopmentCredits>
          {process.env.REACT_APP_FIREBASE_PROJECT_LABEL !== 'benner-metrics' && (
            <>
              <span className="footer-text">
                Desenvolvido por Juristec
              </span>
              <JuristecLogo />
            </>
          )}
        </DevelopmentCredits>
      </InfoContainer>
    </Container>
  );
}

PublicKpiToolbar.propTypes = {
  dashboardDoc: PropTypes.shape({
    name: PropTypes.string,
    displayName: PropTypes.string,
    generatedAt: PropTypes.string,
    expiresAt: PropTypes.string,
  }),
};

PublicKpiToolbar.defaultProps = {
  dashboardDoc: {},
};

export default PublicKpiToolbar;
