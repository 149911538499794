import React, { useContext } from 'react';
import {
  useParams, useHistory,
} from 'react-router-dom';

import urlHash from '../../utils/functions/urlHash';

import KpiCreate from '../../components/KpiCreate';
import { AuthContext } from '../../context/AuthProvider';
import useKpiData from '../../hooks/useKpiData';
import { AlertContext } from '../../context/AlertProvider';

export default function KpiCreatePage() {
  const history = useHistory();
  const { hash } = useParams();

  const { currentUser, userCompany, plans } = useContext(AuthContext);
  const [kpiDataAPI] = useKpiData(currentUser);
  const { setAlertConfig } = useContext(AlertContext);
  const getParams = () => {
    const str = urlHash(hash, true).split('/');
    return { dashboardKey: str[0], kpiKey: str[1] };
  };
  const { dashboardKey, kpiKey } = getParams();

  /**
   * Handles the generation of KPI data based on the KPI type.
   *
   * @param {string} kpiId - The ID of the KPI.
   * @param {string} kpiType - The type of the KPI.
   * @param {Object} kpiBody - The body of the KPI data.
   * @param {string} kpiFile - The fileid associated with the KPI.
   * @param {Function} callback - The callback function to execute after KPI data is generated.
   * @param {Object} [extra={}] - Additional parameters for KPI generation.
   * @param {number} [extra.page=0] - The page number for subset data.
   * @param {string} [extra.sortBy=''] - The field to sort by for subset data.
   * @param {string} [extra.sortOrder='asc'] - The sort order for subset data.
   */
  const handleDynamicKpi = async (kpiId, kpiType, kpiBody, kpiFile, callback, extra = {}) => {
    let data = { error: true };
    if (kpiType === 'SubsetTable') {
      data = await kpiDataAPI.genSubsetData(
        kpiBody, kpiFile, extra.page ?? 0, extra.sortBy ?? '', extra.sortOrder ?? 'asc',
      );
    } else {
      data = await kpiDataAPI.genKpiData(kpiBody, kpiFile);
    }
    if (data?.error) {
      setAlertConfig({
        open: true,
        message: data?.msg,
        severity: 'error',
      });
    }
    if (data) callback((it) => ({ ...it, data }));
  };

  return (
    <>
      <KpiCreate
        dashboardKey={dashboardKey}
        goBack={() => history.goBack()}
        handleDynamicKpi={handleDynamicKpi}
        kpiKey={kpiKey}
        history={history}
        advancedChartsAllowed={userCompany?.advancedCharts ?? plans[userCompany?.plan || 'advanced'].advancedCharts}
      />
    </>
  );
}
