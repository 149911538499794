import styled from 'styled-components';
import { devices } from '../../../juristec-ui/core/breakpoints';
import { gap } from '../../../juristec-ui/styles/theme';

export const UserInfoText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & h4 {
    margin: 0;
  }
  & span {
    font-size: 14px;
  }
  & .removed {
      font-size: 11px;
      color: ${({ theme }) => theme.grey};
  }
`;

export const Options = styled.div`
  display: flex;
  justify-content: center;
  ${gap('15px', 'row')}
  align-items: center;
`;

export const Table = styled.div`
  padding: 0 5%;
  @media ${devices.tablet} {
    padding: 0 20px;
  }
`;

export const UsersTableOverFlow = styled.div`
  overflow-y: auto;
  overflow-x: auto;
  box-sizing: border-box;
  max-height: calc(100vh - 185px);

  @media ${devices.tablet} {
    height: calc(100vh - 125px);
  }
`;

export const BadgeContent = styled.div`
  display: flex;
  justify-content: 'center';
  ${gap('5px', 'row')}
  align-items: center;
`;

export const IconWrapper = styled.div`
  padding: 6px;
  & > svg {
    fill: ${({ theme, color }) => theme[color]}
  }
`;
