/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';

import PropTypes from 'prop-types';
import Avatar from '../../../juristec-ui/core/Avatar';
// import Button from '../../../juristec-ui/core/Button';

import {
  Container,
  HeaderAvatar,
  // GuestBtnContainer,
} from './styled/PublicHeader.styled';

function PublicHeader({ dashboardDoc }) {
  const theme = useTheme();
  const isMounted = useRef();
  const history = useHistory();

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Container>
      <div onClick={() => history.push('/home')} title="Início">
        <img
          src={theme.loginLogo}
          alt="logo"
          width="128px"
          style={{ alignSelf: 'center', cursor: 'pointer' }}
        />
      </div>
      {/* <GuestBtnContainer>
        <Button
          variant="pattern"
          size="small"
          textTransform="none"
        >
          Solicitar acesso como convidado
        </Button>
      </GuestBtnContainer> */}
      <HeaderAvatar>
        <img src={theme.loginLogo} alt="client_logo" />
        <Avatar
          name={dashboardDoc?.displayName}
          src={dashboardDoc?.photoUrl}
          size="medium"
          style={{
            borderColor: `${theme.grey}`,
            color: `${theme.primary}`,
          }}
          onClick={() => {}}
        />
      </HeaderAvatar>
    </Container>
  );
}

PublicHeader.propTypes = {
  dashboardDoc: PropTypes.shape({
    displayName: PropTypes.string,
    photoUrl: PropTypes.string,
  }),
};

PublicHeader.defaultProps = {
  dashboardDoc: {},
};

export default PublicHeader;
