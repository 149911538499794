import React from 'react';
import PropTypes from 'prop-types';

const Unlock = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 576 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M352 144c0-44.2 35.8-80 80-80s80 35.8 80 80l0 48c0 17.7 14.3 32 32 32s32-14.3 32-32l0-48C576 64.5 511.5 0 432 0S288 64.5 288 144l0 48L64 192c-35.3 0-64 28.7-64 64L0 448c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-192c0-35.3-28.7-64-64-64l-32 0 0-48z" />
  </svg>
);
Unlock.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

Unlock.defaultProps = {
  width: '20px',
  height: '22px',
};
export default Unlock;
