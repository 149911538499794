import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

// components
import DataTable from '../../../juristec-ui/core/DataTable';
import TablePagination from '../../../juristec-ui/core/TablePagination';
import Badge from '../../../juristec-ui/core/Badge';

// styled
import {
  Container,
  Infos,
  StyledFont,
  DashboardsList,
  HistoryCount,
  EmptyList,
  Title,
  BadgeContent,
  IconWrapper,
} from './styled/DashSharedWith.styled';

// utils
import { formatDateTime } from '../../../juristec-ui/utils/functions/lab';
import {
  Question, UserAtom, UserConfig, Users,
} from '../../../juristec-ui/icons';

const DashSharedWith = ({
  dash,
  users,
}) => {
  const [page, setPage] = useState(0);

  const theme = useTheme();

  const formatDate = (datetime) => (
    datetime ? formatDateTime(datetime, { time: 'half' }) : 'Não consta'
  );

  const formatStatus = (status) => {
    let permission = 'Desconhecida';
    switch (status) {
      case 'visualizador':
        permission = 'Visualizar';
        break;
      case 'edit':
        permission = 'Copiar';
        break;
      default:
        break;
    }
    return permission;
  };

  const formatRole = (role) => {
    let conditionalColor = 'error';
    let icon = <Question height="20px" />;
    let userType = 'Desconhecido';
    switch (role) {
      case 'super':
        conditionalColor = 'primary';
        icon = <UserConfig width="20px" />;
        userType = 'Proprietário';
        break;
      case 'scientist':
        conditionalColor = 'success';
        icon = <UserAtom width="20px" />;
        userType = 'Cientista';
        break;
      case 'guest':
        conditionalColor = 'warning';
        icon = <Users width="20px" />;
        userType = 'Convidado';
        break;
      default:
        break;
    }
    return (
      <Badge color={conditionalColor} style={{ margin: 'auto' }}>
        <BadgeContent>
          <IconWrapper color={conditionalColor}>
            {icon}
          </IconWrapper>
          {userType}
        </BadgeContent>
      </Badge>
    );
  };

  const columns = [
    {
      field: 'email',
      label: 'Usuário',
      sortable: true,
      valueGetter: (param) => (param?.displayName || param?.email?.split('@')?.[0] || 'Não encontrado'),
    },
    {
      field: 'role',
      label: 'Tipo',
      sortable: true,
      valueGetter: (param) => formatRole(param?.role || ''),
    },
    {
      field: 'value',
      label: 'Permissão',
      sortable: true,
      valueGetter: (param) => formatStatus(param?.value || ''),
    },
    {
      field: 'sharedAt',
      label: 'Compartilhado em',
      sortable: true,
      valueGetter: (param) => formatDate(param?.sharedAt || ''),
    },
  ];

  return (
    <Container>
      <Infos>
        <StyledFont>
          <Title>Dashboard:</Title>
          {' '}
          <span>{dash?.name ? dash.name : 'Sem nome'}</span>
        </StyledFont>
        <HistoryCount>
          <StyledFont>
            <Title>Compartilhamentos:</Title>
            {' '}
            <span>
              {users.length}
            </span>
          </StyledFont>
        </HistoryCount>
      </Infos>
      <DashboardsList>
        {users.length > 0 ? (
          <>
            <DataTable
              columns={columns}
              rowData={users}
              rowColor={theme.tableBackground}
              strippedRowsColor={theme.containerOdd}
              defaultSortField="email"
              defaultSortOrder="ascending"
              headerColor="transparent"
              theadStyle={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                backgroundColor: theme.background,
              }}
              tbodyStyle={{
                fontSize: '14px',
              }}
              usePagination
              page={page}
              itemsPerPage={5}
            />
            <TablePagination
              page={page}
              setPage={setPage}
              totalPages={
                Math.floor(users.length % 5 === 0
                  ? (users.length / 5) - 1
                  : users.length / 5)
              }
            />
          </>
        ) : (
          <EmptyList>
            Esse dashboard não foi compartilhado com ninguém
          </EmptyList>
        )}
      </DashboardsList>
    </Container>
  );
};

DashSharedWith.propTypes = {
  dash: PropTypes.shape({
    name: PropTypes.string,
  }),
  users: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string,
      email: PropTypes.string,
    }),
  ),
};

DashSharedWith.defaultProps = {
  dash: {
    name: '',
  },
  users: {},
};

export default DashSharedWith;
