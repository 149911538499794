import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Select from '../../../juristec-ui/core/SelectNew';
import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import Button from '../../../juristec-ui/core/Button';

import { MainContainer, InfoText, WarningMessage } from './styled/TransferKpi.styled';

/** Transfers all allowed data from one user to another */
const TransferKpi = ({
  currentKpi, dashOptions, close, submit,
}) => {
  const [selected, setSelected] = useState([]);

  const handleSubmit = () => {
    submit(selected.map((s) => s.value));
  };

  return (
    <MainContainer>
      <InfoText>
        Selecione os dashboards para os quais você deseja transferir o KPI
        {' '}
        <b>
          {currentKpi.name}
        </b>
      </InfoText>
      <span style={{ fontSize: '10pt', marginBottom: '10px' }}>
        <WarningMessage>
          <strong>Atenção: </strong>
        </WarningMessage>
        Se os dashboards de destino estiverem compartilhados com outros usuários, estes
        terão acesso ao arquivo utilizado em sua construção.
      </span>
      <Select
        label="Dashboards de destino"
        value={selected}
        onChange={setSelected}
        options={dashOptions}
        atModal
        stayOpen
        searchable
        clearable
        sortBy="ascending"
        multiple
      />
      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={close}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button
          style={{ margin: '5px' }}
          onClick={handleSubmit}
          size="large"
          disabled={selected.length === 0}
        >
          Transferir
        </Button>
      </ActionsGroup>
    </MainContainer>
  );
};

TransferKpi.propTypes = {
  currentKpi: PropTypes.shape({
    name: PropTypes.string,
  }),
  dashOptions: PropTypes.arrayOf(PropTypes.shape({})),
  close: PropTypes.func,
  submit: PropTypes.func,
};

TransferKpi.defaultProps = {
  currentKpi: {
    name: '',
  },
  dashOptions: [],
  close: () => {},
  submit: () => {},
};

export default TransferKpi;
