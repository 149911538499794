import styled from 'styled-components';

export const TextContainer = styled.h4`
  position: relative;
  cursor: pointer;
  & svg {
    display: none;
    fill: ${({ theme }) => theme.grey};
    position: absolute;
    top: 0;
    right: -22px;
    & > path {
      fill: ${({ theme }) => theme.grey};
    }
  }
  &:hover {
    background-color: ${({ theme }) => theme.copyTextHover};
    border-radius: 5px;
    & svg {
      display: initial;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  h4 {
    margin: 0;
    left: 0;
    margin: auto;
    text-align: center;
    white-space: nowrap;
  }

  span {
    font-size: 14px;
  }
`;
