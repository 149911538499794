import styled from 'styled-components';
import { devices } from '../../../../juristec-ui/core/breakpoints';
import { gap } from '../../../../juristec-ui/styles/theme';

export const UserInfoText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & h4 {
    margin: 0;
  }
  & span {
    font-size: 14px;
  }
  & .removed {
      font-size: 11px;
      color: ${({ theme }) => theme.grey};
  }
`;

export const UserInfos = styled.div`
  display: flex;
  align-items: center;
  ${gap('10px', 'row')}

  & .instance-row-user-name-email {
    display: flex;
    flex-direction: column;
    ${gap('3px', 'column')}
  }

  & .name {
    font-size: 20px;
    font-weight: 600;
  }

  & .email {
    font-size: 11px;
    color: ${({ theme }) => theme.grey};
  }

  & .removed {
    font-size: 10px;
    color: ${({ theme }) => theme.grey};
  }
`;

export const Options = styled.div`
  display: flex;
  ${gap('5px', 'row')}
`;

export const BadgeContent = styled.div`
  display: flex;
  justify-content: 'center';
  ${gap('5px', 'row')}
  align-items: center;
`;

export const IconWrapper = styled.div`
  padding: 6px;
  & > svg {
    fill: ${({ theme, color }) => theme[color]}
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${gap('.5rem', 'row')}
  margin: 5px;
  background-color: ${({ theme }) => theme.backgroundOdd};
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.shadow};
  padding: 0.2rem 0.6rem;

  & svg {
    fill: ${({ theme }) => theme.grey};
  }
`;

export const OverflowControl = styled.div`
  height: 50vh;
  overflow: auto;
  overflow-x: hidden;
`;

export const Container = styled.div`
  min-width: 450px;

  & > .divider {
    width: 70%;
  }
`;
