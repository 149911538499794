import React, {
  useEffect, useState, useLayoutEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import Loader from '../../juristec-ui/core/Loader';

import firebase from '../../utils/firebase';
import {
  getRefUser, getRefCompany, getRefOnRegister, getRefSession, getRefCompanySecs, getPlans,
} from '../../utils/firestore';
import useSessionStorage from '../../hooks/useSessionStorage';

import authContextCreated from './provider'; // para n estragar os imports no resto do metrics...

import getRequestMeta, { getPublicRequestMeta } from '../../utils/functions/generateMeta';
import { error as errorLabels } from '../../label';
import { ip } from '../../utils/functions/urls';

import { supportEmail } from '../../options';

export const AuthContext = authContextCreated;

export const AuthProvider = ({ children, hydrate }) => {
  const sessionListener = useRef();
  const [pending, setPending] = useState(true);
  const [msgError, setMsgError] = useState(undefined);
  const [currentUser, setCurrentUser] = useState(null);
  const [plans, setPlans] = useState(null);
  const [user, setUser] = useSessionStorage('user', null);
  const [claimsUser, setClaimsUser] = useSessionStorage('claims', null);
  const [userCompany, setCompanyUser] = useSessionStorage('company', null);
  const [session,, getSession] = useSessionStorage('session', null);

  function clearSessionStorageAuth() {
    setCompanyUser(null);
    setClaimsUser(null);
    setUser(null);
    setMsgError(undefined);
  }

  function setCompanyPhotoUrl(photoUrl) {
    setCompanyUser({ ...userCompany, photoUrl });
  }

  function changeUserConfigs(configs) {
    setUser({ ...user, configs: { ...user.configs, ...configs } });
  }

  function changeUserAttrs(attrs) {
    setUser({ ...user, ...attrs });
  }

  /**
   * Verifica se o usuário possui permissão para determinadas
   * operações em relação ao plano contratado.
   * @param {string} field - O nome da operação que deseja verificar.
   * @param {number} [actualQty] - Quantidade de items atual, caso a operação tenha limite.
   * @returns {boolean} - Retorna `true` se possui permissão, `false` caso contrário.
   * Se `actualQty` for fornecido, retorna true se o campo possui
   * menos do que a quantidade fornecida.
   * Se nenhum valor for fornecido, retorna true se o campo possui
   * um valor verdadeiro ou um valor de plano válido.
   */
  function checkPlanPermission(field, actualQty = null) {
    const fieldValue = userCompany[field] ?? plans[userCompany.plan][field] ?? true;
    return actualQty !== null ? actualQty < fieldValue : fieldValue;
  }

  async function getLoginToken(email, password, gToken) {
    try {
      const msg = {
        ...getPublicRequestMeta(gToken, 'GET', '', `${email}:${password}`),
      };
      const res = await fetch(`${ip}/auth/login`, msg);

      if (res.status === 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      const json = await res.json();
      if (res.status !== 200) {
        return { error: true, msg: errorLabels.fetchGeneric, raw: json.error };
      }

      return { error: false, msg: null, res: json };
    } catch (er) {
      // console.log(er);
      return { error: true, msg: errorLabels.fetchGeneric, raw: `Erro do sistema: ${er.toString()}` };
    }
  }

  async function checkRecaptchaToken(gToken) {
    try {
      const msg = {
        ...getPublicRequestMeta(gToken, 'GET', ''),
      };
      const res = await fetch(`${ip}/auth/captcha`, msg);

      if (res.status === 500) {
        return {
          error: true,
          msg: errorLabels.fetchGeneric,
          raw: 'Verifique se existe algum problema com a sua conexão com a internet e tente novamente mais tarde!',
        };
      }

      const json = await res.json();
      if (res.status !== 200) {
        return { error: true, msg: errorLabels.fetchGeneric, raw: json.error };
      }

      return { error: false, msg: null, res: json };
    } catch (er) {
      // console.log(er);
      return { error: true, msg: errorLabels.fetchGeneric, raw: `Erro do sistema: ${er.toString()}` };
    }
  }

  useLayoutEffect(() => {
    if (currentUser) setPending(false);
    else {
      setPending(true);
    }
  }, [currentUser]);

  useEffect(() => {
    if (!pending && session?.length > 0 && currentUser && !hydrate) {
      const checkUserSession = async (doc) => {
        const sessionDoc = await doc.data();
        if (!user.multiSessions && sessionDoc?.session !== session) {
          firebase.logout();
          alert(`
            A sessão atual será encerrada, pois uma nova sessão foi iniciada com sua conta.
            Se você não iniciou essa nova sessão, entre em contato com o suporte: ${supportEmail()}.
          `);
        }
      };
      sessionListener.current = firebase.snapShotListener(
        getRefSession(currentUser.uid), checkUserSession,
      );
    }
    return () => {
      if (sessionListener?.current) sessionListener.current();
    };
  }, [pending, currentUser, sessionListener, user, session, hydrate]);

  useEffect(() => {
    if (hydrate) {
      // compatibilidade com modalApi
      setCurrentUser(hydrate.currentUser);
      setClaimsUser(hydrate.claimsUser);
      setCompanyUser(hydrate.userCompany);
      setUser(hydrate.user);
      setPlans(hydrate.plans);
      setPending(false);
      return;
    }

    async function getUserInfo(uid) {
      const refUser = getRefUser(uid);
      await refUser
        .get()
        .catch(() => {
          firebase.logout();
          clearSessionStorageAuth();
        })
        .then(async (elen) => {
          if (elen === undefined || elen === null) {
            firebase.logout();
            clearSessionStorageAuth();
          } else {
            const userData = elen.data();
            setUser({ ...userData, id: elen.id });
            let userCompanyInfo = {};
            if ('company' in userData && !userCompany) {
              const refCompany = getRefCompany(userData.company);
              refCompany
                .get()
                .then((company) => {
                  userCompanyInfo = {
                    ...userCompanyInfo,
                    ...company.data(),
                    isAdmin: elen.data()?.isAdmin || false,
                    id: company.id,
                  };
                });
              const secs = [];
              getPlans().get().then((pRes) => {
                setPlans(pRes.data() ?? null);
              });
              getRefCompanySecs(userData.company)
                .get()
                .then((res) => {
                  if (res.size > 0) {
                    res.forEach((doc) => {
                      secs.push(doc.id);
                    });
                  }
                  setCompanyUser({
                    ...userCompanyInfo, apiGedCredentials: secs.includes('l1reports'),
                  });
                });
            } else {
              setCompanyUser({ isAdmin: elen.data()?.isAdmin || false });
            }
          }
        });
    }

    firebase.authStateObserver(async (authUser) => {
      if (authUser) {
        if (!authUser.emailVerified) {
          firebase.auth.currentUser.sendEmailVerification();
          setMsgError('Email não verificado');
          // alert('Verifique sua caixa de email para confirmar seu cadastro!');
          clearSessionStorageAuth();
          firebase.logout();
          return;
        }

        const { email, uid } = firebase.auth.currentUser;
        const userOk = await getRefUser(uid).get();
        if (!userOk.exists()) {
          // console.log('onregister?', email);
          const newUser = await getRefOnRegister(email).get();
          // console.log('nail', email);
          if (newUser.exists()) {
            const userInfo = await newUser.data();
            // await firebase.db.doc(`onregister/${email}`).delete();
            // a responsabilidade de deletar está com o backend
            // console.log('create for ', userInfo);
            const token = await firebase.auth.currentUser.getIdToken();
            // console.log(token);
            const msg = {
              ...await getRequestMeta(token, 'POST', 'JSON'),
              body: JSON.stringify({
                // userName: userInfo.userName,
                // email: userInfo.email,
                plan: userInfo.plan,
                // companyName: userInfo.companyName,
                // document: userInfo.document,
                // erp: userInfo.erp,
                // prefix: userInfo.prefix,
                // phone: userInfo.phone,
                // publiclink: userInfo.publicLink,
                // push: userInfo.push,
                // monitoring: userInfo.monitoring,
                // newMonitoring: userInfo.newMonitoring,
                // newProcess: userInfo.newProcess,
                // oldProcess: userInfo.oldProcess,
              }),
            };
            const res = await fetch(`${ip}/manage/company`, msg);
            const json = await res.json();
            // await firebase.db.doc(`users/${uid}`).update({
            //   lastLogin: authUser.metadata.lastSignInTime
            // });
            getRefUser(uid).update({ lastLogin: new Date(authUser.metadata.lastSignInTime) });
            console.log(json);
          }
        }

        const userOk2 = await getRefUser(uid).get();
        if (!userOk2.exists()) {
          clearSessionStorageAuth();
          firebase.logout();
          return;
        }

        // console.log('validado');
        const date = new Date();
        const time = window.localStorage.getItem('lastActionTime');
        if (time && date.getTime() - time >= 3600000) {
          clearSessionStorageAuth();
          firebase.logout();
        }

        try {
          await getRefOnRegister(email).delete();
        } catch (error) {
          console.log(error);
        }
        // await firebase.db.doc(`onregister/${email}`).get()
        //   .then(async (snapshot) => {
        //     if (snapshot.exists()) {
        //       await firebase.db.doc(`onregister/${email}`).delete()
        //     }
        //   });
        // Acessando claims
        // window.pendo.initialize({
        //   visitor: {
        //     id: authUser.uid, // Required if user is logged in
        //     email: authUser.email, // Recommended if using Pendo Feedback, or NPS Email
        //     // full_name:    // Recommended if using Pendo Feedback
        //     // role:         // Optional
        //     // You can add any additional visitor level key-values here,
        //     // as long as it's not one of the above reserved names.
        //   },
        //   account: {
        //     id: authUser.uid, // Highly recommended
        //     email: authUser.email,
        //     // name:         // Optional
        //     // is_paying:    // Recommended if using Pendo Feedback
        //     // monthly_value:// Recommended if using Pendo Feedback
        //     // planLevel:    // Optional
        //     // planPrice:    // Optional
        //     // creationDate: // Optional
        //     // You can add any additional account level key-values here,
        //     // as long as it's not one of the above reserved names.
        //   },
        // });
        if (!claimsUser) {
          authUser.getIdTokenResult().then((result) => {
            const companyId = result?.claims?.companyId || null;
            if (!userCompany && companyId) {
              getRefCompany(companyId)
                .get()
                .catch(() => {
                  firebase.logout();
                })
                .then((companyResp) => {
                  setCompanyUser(companyResp.data());
                })
                .catch(() => {
                  firebase.logout();
                });
            }
            const claims = result?.claims || {};
            setClaimsUser(claims);
          });
        }
        // Acessando firebase
        if (!user) {
          await getUserInfo(authUser.uid);
        }
      } else if (user || claimsUser || userCompany) {
        clearSessionStorageAuth();
      }
      getSession();
      setCurrentUser(authUser);
      setPending(false);
    });
  }, []);

  return pending ? (
    !hydrate && <Loader debug="authProvider" />
  ) : (
    <AuthContext.Provider
      value={{
        currentUser,
        claimsUser,
        userCompany,
        user,
        plans,
        msgError,
        setCompanyPhotoUrl,
        changeUserConfigs,
        changeUserAttrs,
        getLoginToken,
        checkRecaptchaToken,
        checkPlanPermission,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  hydrate: PropTypes.shape({
    currentUser: PropTypes.shape({}),
    claimsUser: PropTypes.shape({}),
    userCompany: PropTypes.shape({}),
    user: PropTypes.shape({}),
    plans: PropTypes.shape({}),
  }),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
};

AuthProvider.defaultProps = {
  hydrate: null,
};
