import styled from 'styled-components';
import { devices } from '../../breakpoints';

export const StyledAnchor = styled.a`
  color: ${({ theme }) => theme.primary};
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s;
  font-weight: 500;

  &:hover {
    color: ${({ theme }) => theme.primaryHover};
  }

  @media ${devices.phoneS} {
    font-size: 12px;
  }
`;
