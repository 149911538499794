import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  TextContainer, Title, InfoContainer, InfoWrapper, NameContainer,
  Container,
} from './styled/RegisterTrainingDate.styled';
import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import Button from '../../../juristec-ui/core/Button';
import CopyText from '../../../juristec-ui/core/CopyText';
import DatePicker from '../../../juristec-ui/core/DatePicker';

const RegisterTrainingDate = ({
  user, submit, hide,
}) => {
  const [trainedAt, setTrainedAt] = useState(user?.trainedAt ? new Date(user?.trainedAt) : new Date());
  const handleSubmit = async () => {
    await submit(trainedAt);
    hide();
  };
  return (
    <Container>
      <Title>
        Informações do usuário
      </Title>
      <InfoWrapper>
        <InfoContainer>
          <div style={{ fontWeight: 'bold', display: 'inline' }}>Nome: </div>
          <span>{user?.name || user?.displayName}</span>
        </InfoContainer>
        <InfoContainer>
          <div style={{ fontWeight: 'bold', display: 'inline' }}>Email: </div>
          <CopyText text={user?.email} containerStyle={{ display: 'flex', flexDirection: 'row' }} />
        </InfoContainer>
      </InfoWrapper>
      <InfoContainer>
        <DatePicker
          label="Data de treinamento"
          value={trainedAt}
          onChange={(date) => setTrainedAt(date)}
          atModal
        />
      </InfoContainer>
      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={hide}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button
          style={{ margin: '5px' }}
          onClick={handleSubmit}
          size="large"
          disabled={user?.removed}
        >
          Salvar
        </Button>
      </ActionsGroup>
    </Container>
  );
};

RegisterTrainingDate.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    company: PropTypes.shape({
      id: PropTypes.string,
    }),
    name: PropTypes.string,
    displayName: PropTypes.string,
    trainedAt: PropTypes.string,
    removed: PropTypes.bool,
  }).isRequired,
  submit: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
};

RegisterTrainingDate.defaultProps = {
};

export default RegisterTrainingDate;
