/* eslint-disable no-param-reassign */
import { passwordHasher, saferStringHasher } from './urlHash';

// import firebase from '../firebase';
import {
  getRefKpiLayout,
  getRefKpiItemList,
  getRefDashboardItem,

  getRefPublicLayout,
  getRefPublicKpiList,
  getRefPublicDashboard,
  getTransaction,
  getBatch,
  getRefPublicSecret,
} from '../firestore';

const DEFAULTLAYOUT = {
  lg: [], md: [], sm: [], xs: [], xxs: [],
};

const deletePublicDashboard = async (userId, originDashId, publicDashId) => {
  const originDashboard = getRefDashboardItem(userId, originDashId);
  const publicKpis = getRefPublicKpiList(userId, publicDashId);
  const publicLayout = getRefPublicLayout(userId, publicDashId);
  const publicSecrets = getRefPublicSecret(userId, publicDashId);
  const publicDashboard = getRefPublicDashboard(userId).doc(publicDashId);
  const batch = getBatch();
  const publicKpisDocs = await publicKpis.get();
  publicKpisDocs.forEach((d) => {
    const publicDoc = publicKpis.doc(`${d.id}`);
    batch.delete(publicDoc);
  });
  batch.delete(publicLayout);
  batch.delete(publicSecrets);
  batch.delete(publicDashboard);
  batch.set(originDashboard, {
    publicUrl: null,
    publicTimestamp: null,
    publicExpiration: null,
    passwordProtected: null,
  }, { merge: true });
  await batch.commit();
};

const genPublicDashboard = async (
  originalRefs, publicRefs, dashData, password,
) => {
  await getTransaction(async (transaction) => {
    const dashOrigin = await transaction.get(originalRefs.dashDoc);
    const dash = {
      originalId: dashOrigin.id,
      ...dashOrigin.data(),
      ...dashData,
    };

    const layoutOrigin = await transaction.get(originalRefs.layout);
    const layout = layoutOrigin.data() || DEFAULTLAYOUT;

    if (password !== undefined) {
      transaction.set(publicRefs.secrets, {
        password: password ? passwordHasher(password) : null,
      });
    }

    transaction.set(originalRefs.dashDoc, {
      publicUrl: dashData.publicUrl,
      publicTimestamp: dashData.generatedAt,
      publicExpiration: dashData.expiresAt,
      passwordProtected: dashData.passwordProtected,
    }, { merge: true });
    transaction.set(publicRefs.dashDoc, dash, { merge: true });
    transaction.set(publicRefs.layout, layout);

    const oldKpisRaw = await publicRefs.kpis.get();

    oldKpisRaw.docs.forEach((d) => {
      const publicDoc = publicRefs.kpis.doc(`${d.id}`);
      transaction.delete(publicDoc);
    });

    const kpisOrigin = await originalRefs.kpis.get();

    kpisOrigin.docs.forEach((d) => {
      const publicDoc = publicRefs.kpis.doc(`${d.id}`);
      const originalKpi = { ...d.data() };
      transaction.set(publicDoc, originalKpi);
    });
  });
  return {
    publicUrl: dashData.publicUrl,
    localTimestamp: dashData.generatedAt,
    passwordProtected: dashData.passwordProtected,
    error: false,
  };
};

const newPublicDashboard = async (originDashId, dashData, userId, password) => {
  const originalRefs = {
    dashDoc: getRefDashboardItem(userId, originDashId),
    kpis: getRefKpiItemList(userId, originDashId),
    layout: getRefKpiLayout(userId, originDashId),
  };
  const publicDashRef = getRefPublicDashboard(userId).doc();
  const publicRefs = {
    dashDoc: publicDashRef,
    kpis: getRefPublicKpiList(userId, publicDashRef.id),
    layout: getRefPublicLayout(userId, publicDashRef.id),
    secrets: getRefPublicSecret(userId, publicDashRef.id),
  };
  dashData.publicUrl = `/public/${saferStringHasher(`${publicDashRef.id}/${userId}`)}`;
  return genPublicDashboard(originalRefs, publicRefs, dashData, password);
};

const updatePublicDashboard = async (originDashId, publicDashId, dashData, userId) => {
  const originalRefs = {
    dashDoc: getRefDashboardItem(userId, originDashId),
    kpis: getRefKpiItemList(userId, originDashId),
    layout: getRefKpiLayout(userId, originDashId),
  };
  const publicRefs = {
    dashDoc: getRefPublicDashboard(userId).doc(publicDashId),
    kpis: getRefPublicKpiList(userId, publicDashId),
    layout: getRefPublicLayout(userId, publicDashId),
    secrets: getRefPublicSecret(userId, publicDashId),
  };
  dashData.publicUrl = `/public/${saferStringHasher(`${publicDashId}/${userId}`)}`;
  return genPublicDashboard(originalRefs, publicRefs, dashData, undefined);
};

export default async function (userDoc, dashDoc, operation, pwd, expDt) {
  const originDashId = dashDoc.id;
  if (!originDashId) return { error: true };

  const oldUrl = dashDoc.publicUrl ?? '/_';
  const publicDashId = saferStringHasher(oldUrl.substring(oldUrl.lastIndexOf('/') + 1), true).split('/')?.[0] ?? '';

  const timestamp = new Date();

  if (operation === 'delete') {
    await deletePublicDashboard(userDoc.id, originDashId, publicDashId);
    return { publicUrl: null, localTimestamp: timestamp, error: false };
  }

  const dashData = {
    photoUrl: userDoc.photoUrl,
    displayName: userDoc.name,
    generatedAt: timestamp.toISOString().replace('Z', '+00:00'),
    expiresAt: expDt === null ? 'NEVER' : expDt.toISOString().replace('Z', '+00:00'),
    passwordProtected: !!pwd,
  };

  if (operation === 'update') {
    return updatePublicDashboard(originDashId, publicDashId, dashData, userDoc.id);
  }

  return newPublicDashboard(originDashId, dashData, userDoc.id, pwd);
}
