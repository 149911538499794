/* eslint-disable no-param-reassign */
import React, {
  useContext, useEffect, useState, useRef,
} from 'react';
import {
  useParams, useHistory,
} from 'react-router-dom';
import { useFullScreenHandle } from 'react-full-screen';
import { useTheme } from 'styled-components';
import isEqual from 'lodash.isequal';

// components
import { useReactToPrint } from 'react-to-print';
import GridKpis from '../../components/GridKpis';
import useGridKpi from '../../hooks/useGridKpi';
import GridKpisToolbar from '../../components/Toolbars/GridKpisToolbar';
import Mural from '../../components/Mural';
import ShareList, { PublicLinkAlert } from '../../components/Modals/ShareList';
import CreateTemplate from '../../components/ProgressiveModals/CreateTemplate';
import DashboardDependencies from '../../components/Modals/DashboardDependencies';
import NoKpi from '../../juristec-ui/kpis/grid/NoKpi';
import Loader from '../../juristec-ui/core/Loader';
import GlobalFiltersColumns from '../../components/Modals/GlobalFiltersColumns';
import IframeCreate from '../../components/Modals/IframeCreate';
import SubsetTableCreate from '../../components/Modals/SubsetTableCreate';
import TitleModal from '../../components/Modals/SubsetTableCreate/TitleModal';
import FileReport from '../../components/FilesView/FileReport';
import FileVisualize from '../../components/Modals/FileVisualize';
import FileUpload from '../../components/Modals/FileUpload';

// hooks
import useDeviceType from '../../juristec-ui/hooks/useDeviceType';

// functions & others
import uuidv4 from '../../juristec-ui/utils/functions/randomUUID';
import { TourContext } from '../../context/TourProvider';
import { AuthContext } from '../../context/AuthProvider';
import { UsersContext } from '../../context/UsersProvider';
import { ModalContext } from '../../context/ModalProvider';
import { AlertContext } from '../../context/AlertProvider';
import { FilesContext } from '../../context/FilesProvider';
import { TemplateContext } from '../../context/TemplateProvider';
// import { AlertContext, ModalContext, Loader } from '../../components/Juristec/core';
import useGridDashboard from '../../hooks/useGridDashboard';
import useKpiData from '../../hooks/useKpiData';
import urlHash from '../../utils/functions/urlHash';
import renameFile from '../../utils/functions/renameFile';
import { shareDashOptions } from '../../options';
import { error } from '../../label';

import { verifyFileImgType, verifyFileSize } from '../../juristec-ui/utils/validators/fileValidators';
import getDefaultStyle from '../../juristec-ui/kpis/utils/chartTools/getDefaultStyle';

const printLoadMsg = 'Preparando a impressão. Esta tarefa pode levar alguns segundos...';

const getType = (typeLabel) => {
  switch (typeLabel) {
    case 'date':
      return 'datetime64[ns]';
    case 'float':
      return 'float64';
    default:
      return 'category';
  }
};

export default function KpisPage() {
  const history = useHistory();
  const { hash } = useParams();
  const theme = useTheme();

  const getParams = () => {
    const str = urlHash(hash, true).split('/');
    return { dashboardKey: str[0], userId: str[1] };
  };
  const { dashboardKey, userId } = getParams();
  const {
    currentUser, userCompany, user, claimsUser, plans,
  } = useContext(AuthContext);
  const { state: usersState } = useContext(UsersContext);
  const { state: filesState, filesAPI } = useContext(FilesContext);
  const totalSize = parseFloat(userCompany?.volumeData || 0);
  const { closeModal, setModalConfig } = useContext(ModalContext);
  const { setAlertConfig } = useContext(AlertContext);
  const { state: templateState, templateAPI } = useContext(TemplateContext);

  const gridKpiRef = useRef();
  const {
    tourOpen, nextStep, refreshTour, remakeTourList,
  } = useContext(TourContext);
  const handle = useFullScreenHandle();
  const onBeforeGetContentResolve = useRef();

  const [kpiDataAPI] = useKpiData(currentUser);

  const device = useDeviceType();

  const isOwner = userId === currentUser.uid;
  const dashboardKeyValid = dashboardKey.split('_')[1] || dashboardKey;
  // const dashboardKeyValid = isOwner ? dashboardKey : dashboardKey.split('_')[1];

  const [gridKpiState, gridKpiAPI] = useGridKpi(userId, dashboardKeyValid, currentUser, user);
  const [editPainel, setEditPainel] = useState(null);
  const [openMural, setOpenMural] = useState(false);
  const [gridBreakpoint, setGridBreakpoint] = useState('lg');
  const [showToolbar, setShowToolbar] = useState(true);

  const [showToPrint, setShowToPrint] = useState(false);
  const [manualLoad, setManualLoad] = useState(false);

  const [dashFiles, setDashFiles] = useState([]);
  const [globalFilterSelectors, setGlobalFilterSelectors] = useState({});
  const [globalFilterLimits, setGlobalFilterLimits] = useState({});
  const globalFilters = useRef({});
  const globalFiltersOrder = useRef([]);
  const dataToFilter = useRef({});
  const cron = useRef();
  // const dashLoaded = useRef(false);

  const {
    dashboardDoc,
    kpiItemList,
    kpisLayout,
    collection,
    isLoading,
  } = gridKpiState;

  useEffect(() => {
    gridKpiAPI.loadCardsFromCollection();
  }, [gridKpiAPI]);

  useEffect(() => {
    if (dashboardDoc && (!dashboardDoc.status || dashboardDoc.isDeleted)) {
      setAlertConfig({
        type: 'warning',
        text: 'Dashboard não encontrado!',
      });
      history.replace('/home');
    }
  }, [dashboardDoc, history]);

  useEffect(() => {
    if (dashboardDoc?.globalFilters && dashFiles.length > 0) {
      setGlobalFilterSelectors(dashboardDoc.globalFilters?.reduce((aux, gf) => {
        const uid = gf.uid || uuidv4();
        aux[uid] = {
          ...gf,
          uid,
          selector: gf.type === 'datetime64[ns]' ? 'year' : '',
          filename: dashFiles.find((ff) => ff.file_id === gf.database)?.filename || '',
          isOpen: false,
        };
        return aux;
      }, {}));
      globalFilters.current = {};
    }
    if (dashboardDoc?.globalLimits && dashFiles.length > 0) {
      setGlobalFilterLimits(dashboardDoc.globalLimits?.reduce((aux, gl) => {
        const uid = gl.uid || uuidv4();
        aux[gl.database + gl.column] = {
          ...gl,
          uid,
          values: gl.values,
          filename: dashFiles.find((ff) => ff.file_id === gl.database)?.filename || '',
        };
        return aux;
      }, {}));
    }
  }, [dashboardDoc?.globalFilters, dashboardDoc?.globalLimits, dashFiles.length]);

  useEffect(() => {
    if (!templateState.templates.length > 0 && !templateState.isLoading) {
      (async () => {
        await templateAPI.getTemplates();
      })();
    }
    if (!filesState.started && !filesState.fileLoading) {
      (async () => {
        await filesAPI.init();
      })();
    }
  }, [filesState.started, filesAPI, filesState.fileLoading]);

  useEffect(() => {
    if ((
      filesState?.files?.length > 0
      || filesState?.instanceFiles?.length > 0
    ) && dashboardDoc?.databases) {
      setDashFiles(filesState.instanceFiles.reduce((aux, fileInfo) => {
        if (dashboardDoc.databases.includes(fileInfo.file_id)) {
          const file = filesState.files.find((fullFile) => fullFile.file_id === fileInfo.file_id);
          if (file) {
            aux.push({
              ...file,
              label: file.filename,
              value: file.file_id,
              id: file.file_id,
              allowed: true,
            });
          } else {
            aux.push({
              ...fileInfo,
              allowed: false,
            });
          }
        }
        return aux;
      }, []));
    }
  }, [filesState.files, filesState.instanceFiles, dashboardDoc?.databases]);

  const closeMural = () => setOpenMural(false);

  const getAllInfos = async () => {
    const res = await gridKpiAPI.loadCardsFromCollection();
    return res;
  };

  const changeCurrentSnap = async (snapId) => {
    const result = await gridKpiAPI.loadCardsFromCollection(snapId);
    if (result.error) {
      setAlertConfig({
        type: 'error',
        text: result.msg,
        child: `${result.raw}`,
      });
    }
  };

  function snapshotReachedtLimit(snaps) {
    const plan = userCompany?.plan || 'advanced';
    const companyPlan = plans[plan];
    const limit = +(userCompany?.snapshots || companyPlan?.snapshots);
    if (!limit) throw new Error('Quantidade de snapshots não numerico');
    return snaps.length >= limit;
  }

  const subsetTableReachedLimit = () => {
    const subsetTablesInDash = kpiItemList.filter((k) => k.type === 'SubsetTable');
    const companyPlan = plans[userCompany?.plan || 'advanced'];
    const subsetTableLimit = userCompany?.subsetTableLimit ?? companyPlan.subsetTableLimit;
    return subsetTablesInDash.length >= subsetTableLimit;
  };

  const handleCreateSnapshot = async (snapName) => {
    try {
      const oldsSnaps = dashboardDoc.snapshots || [];
      const plan = userCompany.plan || 'advanced';
      if (snapshotReachedtLimit(oldsSnaps)) {
        switch (plan) {
          case 'premium':
            setAlertConfig({
              type: 'error',
              text: 'Limite de Stories alcançados!',
              child: 'Para incluir um novo story você deve apagar algum dos existentes para liberar espaço.',
            });
            break;
          default:
            setAlertConfig({
              type: 'error',
              text: 'Limite de Stories alcançados!',
              child: `Seu plano permite apenas ${userCompany?.snapshots ?? plans[userCompany.plan].snapshots} Stories. Para incluir um novo story você deve apagar algum dos existentes para liberar espaço ou fazer um upgrade de plano.`,
            });
            break;
        }
        return;
      }
      const result = await gridKpiAPI
        .saveSnapshot(snapName, oldsSnaps, kpiItemList, kpisLayout);
      // (snapName, oldSnaps, cardList, cardsLayout, deleteOlder);
      if (result.error) {
        setAlertConfig({
          type: 'error',
          text: result.msg,
          child: `${result.raw}`,
        });
        return;
      }

      setAlertConfig({
        type: 'success',
        text: 'Story criado com sucesso!',
      });
      if (tourOpen) nextStep();
      closeModal();
    } catch (er) {
      setAlertConfig({
        type: 'error',
        text: 'Algo deu errado!',
        // child: `${result.raw}`,
      });
    }
  };

  const handleScheduleSnapshot = async (cronCode, snapName) => {
    const plan = userCompany?.plan || 'advanced';
    const scheduledSnapshotsAllowed = (
      userCompany?.scheduledSnapshotsAllowed
      ?? plans[plan].scheduledSnapshotsAllowed
    );
    if (!scheduledSnapshotsAllowed) {
      setAlertConfig({
        type: 'error',
        text: 'Você não tem permissão para agendar Stories!',
        child: 'Para agendar Stories você precisa fazer upgrade de plano.',
      });
      return;
    }
    const snaps = dashboardDoc.snapshots || [];
    if (snapshotReachedtLimit(snaps)) {
      switch (userCompany.plan) {
        case 'premium':
          setAlertConfig({
            type: 'error',
            text: 'Limite de Stories alcançados!',
            child: 'Para incluir um novo story você deve apagar algum dos existentes para liberar espaço.',
          });
          break;
        default:
          setAlertConfig({
            type: 'error',
            text: 'Limite de Stories alcançados!',
            child: `Seu plano permite apenas ${userCompany?.snapshots ?? plans[userCompany.plan].snapshots} Stories. Para incluir um novo story você deve apagar algum dos existentes para liberar espaço ou fazer um upgrade de plano.`,
          });
          break;
      }
      return;
    }

    const result = await gridKpiAPI.scheduleSnapshot(cronCode, snapName);
    if (result.error) {
      setAlertConfig({
        type: 'error',
        text: result.msg,
        child: `${result.raw}`,
      });
      return;
    }

    setAlertConfig({
      type: 'success',
      text: 'Story agendado com sucesso!',
    });
    if (tourOpen) nextStep();
    closeModal();
  };

  // editar o nome do snapshot
  const handleEditSnapshot = async (name, snapId) => {
    const oldSnaps = dashboardDoc.snapshots;
    const result = await gridKpiAPI.editSnapshot({ name, snapId }, oldSnaps);
    if (result.error) {
      setAlertConfig({
        type: 'error',
        text: result.msg,
        child: `${result.raw}`,
      });
      return;
    }

    setAlertConfig({
      type: 'success',
      text: 'Story editado com sucesso!',
    });
    closeModal();
  };

  // deletar o nome do snapshot
  const handleDeleteSnapshot = (snap) => {
    const deleteSnapshot = async () => {
      const oldSnaps = dashboardDoc.snapshots;
      const result = await gridKpiAPI.removeSnapshot(snap, oldSnaps);
      if (result.error) {
        setAlertConfig({
          type: 'error',
          text: result.msg,
          child: `${result.raw}`,
        });
        return;
      }

      setAlertConfig({
        type: 'success',
        text: 'Story apagado com sucesso!',
      });

      // se deletar no qual está selecionado, volta para o 'kpis'
      if (collection === snap.snapId) {
        await changeCurrentSnap();
      }
      closeModal();
    };

    setAlertConfig({
      type: 'warning',
      text: 'Tem certeza que deseja excluir o story?',
      child: (
        <span>
          O story
          {' '}
          <strong style={{ fontStyle: 'italic' }}>
            {snap.name}
          </strong>
          {' '}
          não poderá ser recuperado!
        </span>
      ),
      withoutConfirm: true, // tirar o ok do alerta
      withFunction: true, // colocar btn cancelar e confirmar
      confirmFunction: deleteSnapshot,
    });
  };

  const handleCardStyles = async (cardStyles) => {
    const result = await gridKpiAPI.setCardStyles(cardStyles);
    if (result.error) {
      setAlertConfig({
        type: 'error',
        text: result.msg,
        child: `${result.raw}`,
      });
      return;
    }

    setAlertConfig({
      type: 'success',
      text: 'Estilo editado com sucesso!',
    });
    closeModal();
  };

  const handleSaveLayout = async (newLayout) => {
    const result = await gridKpiAPI.saveLayout(newLayout);
    if (result.error) setAlertConfig({ type: 'error', text: 'Erro ao salvar o layout' });
  };

  const [{ isLoading: gridAPILoading, dashboards }, gridAPI] = useGridDashboard(currentUser, user);

  const handleShare = () => {
    const companyPlan = plans[userCompany.plan];
    const genSuccessMsg = (dashName, sharedUsers = [], missing) => {
      let child = '';
      let type = 'success';
      if (sharedUsers.length > 0) {
        child = `O dashboard ${dashName} está compartilhado com ${sharedUsers.map((u) => (
          `${u.name} (${shareDashOptions.get(u.sharePermission?.value)})`)).join(', ')}.`;
        if (missing?.deleted?.length > 0 || missing?.noPermission?.length > 0) {
          type = 'warning';
          child += " Porém alguns KPI's dependem de";
          if (missing.deleted.length > 0) {
            child += ` ${missing.deleted.length} arquivos não encontrados${missing.noPermission.length > 0 ? ' e' : '.'}`;
          }
          if (missing.noPermission.length > 0) child += ` ${missing.deleted.length} arquivos que não são seus.`;
        }
        child = (
          <>
            <div>
              {child}
            </div>
            <div>
              <span style={{ color: theme.warning, fontWeight: 'bold' }}>
                Atenção:
              </span>
              {' '}
              <span>
                Filtros globais e variáveis de controle podem não estar disponíveis para os usuários
                compartilhados caso você não seja o proprietário dos arquivos dependentes.
              </span>
            </div>
          </>
        );
      } else {
        child = `O dashboard ${dashName} não está compartilhado com outros usuários.`;
      }
      return {
        text: 'Opções de compartilhamento atualizadas!',
        type,
        child,
      };
    };

    const submitShare = async (selected, removed) => {
      const result = await gridAPI.shareDashboard(
        gridKpiState.dashboardDoc, usersState.users, selected, removed, filesState.files,
      );
      if (result.error) {
        setAlertConfig({
          type: 'error',
          text: result.msg,
          child: `${result.raw}`,
        });
        return;
      }
      gridKpiAPI.loadCardsFromCollection();
      // await gridKpiAPI.setDashInfo(gridKpiState.dashboardDoc); // atualizar o doc //
      setAlertConfig(genSuccessMsg(gridKpiState.dashboardDoc.name, selected, result.missing));
      closeModal(); // aqui...
    };

    const genPublicUrl = async (operation, publicUrl, pwd, expDt) => {
      const res = await gridKpiAPI.genPublicKpis(
        { ...dashboardDoc, publicUrl: publicUrl ?? dashboardDoc.publicUrl },
        operation,
        pwd,
        expDt,
      );
      const isDel = operation === 'delete';
      if (res.error) {
        setAlertConfig({
          type: 'error',
          text: `Não foi possível ${isDel ? 'desativar o link público' : 'gerar cópia pública'}. Tente novamente mais tarde`,
        });
      } else {
        setAlertConfig({
          type: 'success',
          text: `Link público ${isDel ? 'desativado' : 'atualizado'} com sucesso.`,
          child: !isDel ? <PublicLinkAlert /> : null,
        });
        gridKpiAPI.loadCardsFromCollection();
      }
      return res;
    };
    setModalConfig({
      title: 'Compartilhar Dashboard',
      close: closeModal,
      children: (
        <ShareList
          users={usersState.users}
          owner={gridKpiState?.dashboardDoc.owner}
          publicLink={userCompany.publicLink}
          sharedWith={gridKpiState?.dashboardDoc?.sharedWith}
          submit={submitShare}
          close={closeModal}
          publicUrl={dashboardDoc.publicUrl}
          publicTimestamp={dashboardDoc.publicTimestamp}
          genPublicUrl={genPublicUrl}
          permissionsOpts={shareDashOptions.getOptions()}
          expiresAt={dashboardDoc.publicExpiration}
          passwordProtected={dashboardDoc?.passwordProtected}
          getAllInfos={getAllInfos}
          permissionsPlan={{
            daysToExpire: (
              userCompany.publicLinkExpirationDays ?? companyPlan.publicLinkExpirationDays
            ),
            password: userCompany.publicLinkPwd ?? companyPlan.publicLinkPwd,
          }}
        />
      ),
    });
  };

  const handleCreateTemplate = async () => {
    const submitNewTemplate = async (template) => {
      const cardStyles = dashboardDoc.cardStyles || {};
      const result = await gridKpiAPI.createTemplate(
        { ...template, cardStyles }, kpiItemList, kpisLayout,
      );
      if (result.error) {
        setAlertConfig({
          type: 'error',
          text: result.msg,
          child: `${result.raw}`,
        });
        return;
      }

      setAlertConfig({
        type: 'success',
        text: 'Template criado com sucesso!',
      });
      closeModal();
    };

    // Function to check if the image satisfies the specified conditions
    const checkFileIsImg = (file) => {
      const errorMsg = verifyFileImgType(file) || verifyFileSize(file, 1000000);
      if (errorMsg.length > 0) {
        setAlertConfig({ type: 'error', text: errorMsg });
        return false;
      }
      return true;
    };

    const templateCategories = Object.values(templateState.templates?.reduce((aux, t) => {
      if (t.category) {
        aux[t.category] = { label: t.category, value: t.category, id: `category-${t.category}` };
      }
      return aux;
    }, {}) || {});

    const columnsRequired = await gridKpiAPI.getColumnDependecies(
      kpiItemList, dashboardDoc?.globalFilters,
    );
    // const aux = Object.keys(columnsRequired).reduce((acc, cur) => {
    //   acc[cur] = { type: columnsRequired[cur], description: '' };
    //   return acc;
    // }, {});

    setModalConfig({
      title: 'Criar Template',
      children: (
        <CreateTemplate
          hide={closeModal}
          submitData={submitNewTemplate}
          templateVars={columnsRequired}
          categories={templateCategories}
          checkImage={checkFileIsImg}
        />
      ),
    });
  };

  const redirectToKpiCreation = () => {
    history.push(`/kpi/${urlHash(`${dashboardKey}/new`)}`);
  };

  const redirectToInsightCreation = () => {
    history.push(`/label/${urlHash(`${dashboardKeyValid}/new`)}`);
  };

  useEffect(() => {
    if (showToPrint) {
      setTimeout(() => {
        setManualLoad(false);
        onBeforeGetContentResolve.current();
      }, 5000);
    }
  }, [showToPrint, onBeforeGetContentResolve]);

  const handleOnBeforeGetContent = () => {
    setManualLoad('print');
    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;
      setTimeout(() => {
        setShowToPrint(true);
      }, 1000);
    });
  };

  const reactToPrintContent = React.useCallback(() => (
    gridKpiRef.current
  ), [gridKpiRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    onBeforeGetContent: handleOnBeforeGetContent,
    onAfterPrint: () => setShowToPrint(false),
  });

  const handleFullScreen = () => {
    handle.enter();
  };

  const getUserName = (uId) => (
    usersState?.users?.find((u) => u.id === uId)?.name || ''
  );

  /**
   * @async Fetch the values of the control variable
   * @param {object} database Database file id
   * @param {string} control Variable name
   * @param {string} dateType Date selector (only for date variable)
   * @returns {array} Value array options
   */
  const getControlValues = async (database, control, dateType, limits) => {
    const file = dashFiles.find((df) => df.file_id === database);
    if (!file) {
      return {
        fileError: [
          'Arquivo necessário não encontrado',
          'O arquivo responsável pela geração deste KPI foi excluído. Desta forma, não é possivel utilizar esta ferramenta.',
        ],
      };
    } if (!file.allowed) {
      return {
        fileError: [
          'Permissões insuficientes',
          `Você não possui permissão de leitura para o arquivo "${file.filename}".`,
          `Para utilizar esta ferramenta, solicite as permissões necessárias ao dono do arquivo: ${getUserName(file.owner)}`,
        ],
      };
    }
    const limitList = (
      limits ?? Object.values(globalFilterLimits).filter((gfl) => gfl.database === database)
    );
    const uniqueRes = await filesAPI.getUnique(database, control, dateType, limitList);
    if (uniqueRes.error) {
      setAlertConfig({ type: 'error', text: error.generic, child: uniqueRes.msg });
      return { opts: [] };
    } if (uniqueRes.res) {
      return { opts: uniqueRes.res.map((val) => ({ label: val, value: val, id: val })) };
    }
    return { opts: [] };
  };

  const fetchDynamicKpi = async () => {
    const promises = Object.entries(dataToFilter.current).map(([fileId, Kpis]) => (
      kpiDataAPI.genMultData(Kpis, fileId)
    ));
    if (promises.length > 0) {
      await Promise.all(promises);
    }
    // await kpiDataAPI.genMultData(dataToFilter.current, kpiFile);
    setManualLoad(false);
    dataToFilter.current = {};
  };

  const addToFilter = (kpiId, kpiType, kpiBody, kpiFile, callback, extra = {}) => {
    clearTimeout(cron.current);
    dataToFilter.current[kpiFile] = {
      ...(dataToFilter.current[kpiFile] ?? {}),
      [kpiId]: {
        id: kpiId,
        type: kpiType,
        body: kpiBody,
        callback,
        extra,
      },
    };
    cron.current = setTimeout(() => {
      fetchDynamicKpi();
    }, 1000);
  };

  /** Request temporary kpi data */
  const handleDynamicKpi = (kpiId, kpiType, kpiBody, kpiFile, callback, extra = {}) => {
    if (collection === 'kpis') {
      setManualLoad(true);
      addToFilter(kpiId, kpiType, kpiBody, kpiFile, callback, extra);
    }
  };

  const handleValueModifiers = async (fileId, kpiId, body) => {
    setManualLoad(true);
    const dtRes = await kpiDataAPI.genKpiData(body, fileId);
    if (dtRes.error) {
      setAlertConfig({
        type: 'error',
        text: dtRes.msg,
        // child: `${dtRes.raw.error}`,
      });
      setManualLoad(false);
      return false;
    }
    const dtSave = await gridKpiAPI.saveKpiValueFilters(kpiId, body, dtRes);
    if (dtSave.error) {
      setAlertConfig({
        type: 'error',
        text: dtSave.msg,
        child: `${dtSave.raw}`,
      });
      setManualLoad(false);
      return false;
    }
    setManualLoad(false);
    return true;
  };

  const handleShowToolbar = () => {
    setShowToolbar(!showToolbar);
  };

  const getUsersShareds = () => {
    try {
      if (dashboardDoc) {
        const sharedIds = Object.keys(dashboardDoc.sharedWith || {});
        sharedIds.push(dashboardDoc.owner);
        const usersShareds = usersState.users.filter((u) => sharedIds.includes(u.id));
        return usersShareds;
      }
      return [];
    } catch (err) {
      return [];
    }
  };

  const handleArchiveComment = (commentId) => {
    setAlertConfig({
      type: 'warning',
      text: 'Tem certeza que deseja remover este comentário?',
      withoutConfirm: true,
      withFunction: true,
      confirmFunction: () => gridKpiAPI.archiveComment(commentId),
    });
  };

  const handleDependencies = () => {
    /**
   * Opens an alert showing the file's update information
   * Attention with the logic of this function. A copy of that is used in FilesPage component
   * @param {object} file file info
   */
    const getReportStatus = async (file, f) => {
      const handleUpdate = async () => {
        const res = await filesAPI.baseUpdate(file.filename, file.file_id);
        if (res.error) {
          setAlertConfig({
            type: 'error',
            text: res.msg,
            child: res.raw,
          });
          return;
        }
        setAlertConfig({
          type: 'success',
          text: `Arquivo ${file.filename} atualizado com sucesso!`,
        });
        gridKpiAPI.loadCardsFromCollection();
        filesAPI.init();
        f();
      };

      const updateFile = () => {
        setAlertConfig({
          type: 'warning',
          text: `Atualizar o relatório ${file.filename}?`,
          withoutConfirm: true,
          withFunction: true,
          confirmFunction: () => handleUpdate(),
        });
      };

      const res = await filesAPI.getFile(file.file_id);
      if (res.error) {
        setAlertConfig({
          type: 'error',
          text: res.msg,
          child: res.raw,
        });
        return;
      }

      const infoFile = {
        ...res.res,
      };
      const owner = usersState.users.find((u) => u.uid === infoFile.owner);

      let status = '';
      let msg = '';
      if (infoFile.lastAttemptStatus === 'ERROR') {
        status = 'error';
        msg = 'Erro ao atualizar o relatório!';
      } else if (infoFile.lastAttemptStatus === 'WARNING') {
        status = 'warning';
        msg = 'Relatório atualizado!';
      } else {
        status = 'success';
        msg = 'Relatório atualizado com sucesso!';
      }

      if ((user.id === file.owner || file.shared_write.includes(user.id))
        && ['GoogleDrive', 'Drive', 'OneDrive', 'PgSql', 'MySql', 'Benner', 'LegalOne', 'LegalOneExtractor', 'L1ReportsApi', 'Mail'].includes(file.origin)) {
        setAlertConfig({
          type: status,
          text: msg,
          child: <FileReport
            file={infoFile}
            owner={owner}
            adminAccess={false}
            manualUpdate={updateFile}
          />,
        });
      } else {
        setAlertConfig({
          type: status,
          text: msg,
          child: <FileReport
            file={infoFile}
            owner={owner}
            adminAccess={false}
          />,
        });
      }
    };

    const handleFile = async (toHandleFile, overwrite, overwriteName) => {
      let newFile = toHandleFile;
      if (overwriteName) {
        newFile = renameFile(newFile, overwriteName);
      }
      const {
        res: fileColumnsInfos,
        msg,
        error: handleError,
        raw,
      } = await filesAPI.uploadFile(newFile, overwrite);
      if (tourOpen) refreshTour();
      if (handleError) {
        setAlertConfig({ type: 'error', text: `${msg}`, child: raw });
        return null;
      }
      return { name: newFile.name, ...fileColumnsInfos };
    };

    const handleUpdateSubmit = async (newFile, oldFile, f) => {
      if (tourOpen) { remakeTourList('updateFile'); }

      if (!newFile || !oldFile) return;
      const newFileName = (newFile.filename || newFile.name);

      const oldFileInfo = await filesAPI.getFileSample(oldFile.file_id, false, 10);
      if (oldFileInfo.error || !oldFileInfo.res) {
        setAlertConfig({
          type: 'error',
          text: oldFileInfo.msg,
          child: oldFileInfo.raw,
        });
        return;
      }

      newFile.types = oldFileInfo.res.types;

      const handleSubmit = async (startLine) => {
        if (tourOpen) nextStep();
        const resUpdate = await filesAPI.baseUpdate(newFileName, oldFile.file_id, startLine);
        if (resUpdate.error) {
          setAlertConfig({
            type: 'error',
            text: resUpdate.msg,
            child: resUpdate.raw,
          });
          return;
        }
        setAlertConfig({
          type: 'success',
          text: 'Arquivo atualizado com sucesso',
          child: resUpdate.res.message,
        });
        gridKpiAPI.loadCardsFromCollection();
        filesAPI.init();
        f();
      };

      setModalConfig({
        title: 'Atualização de arquivo',
        className: 'updateFileModal',
        confirmClassName: 'submit_update_file',
        children: (
          <FileVisualize
            fileData={newFile}
            reopenFile={filesAPI.reopen}
            fileName={newFileName}
            handleSubmit={handleSubmit}
            hide={closeModal}
          />
        ),
      });
    };

    /**
    * Opens an modal to upload a new file to be updated
    * Attention with the logic of this function. A copy of that is used in FilesPage component
    * @param {object} file file info
    */
    const handleUpdateFile = async (file, f) => {
      const handleUpdateByFile = async (newFile) => (
        handleUpdateSubmit(await handleFile(newFile, true), file, f)
      );

      setModalConfig({
        title: 'Atualização de arquivo',
        close: closeModal,
        className: 'updateFileModal',
        children: (
          <FileUpload
            availableSize={(totalSize - filesState.size) * 1024 ** 2}
            uploadFile={handleUpdateByFile}
            updateMode
            updatingFileName={file.filename.split('.metrics')[0]}
            hide={closeModal}
            // tourContext={{ tourOpen, nextStep }}
          />
        ),
      });
    };

    setModalConfig({
      title: 'Lista de dependências',
      yesLabel: 'Ok',
      children: (
        <DashboardDependencies
          currentUser={user}
          dashboard={dashboardDoc}
          files={dashFiles}
          users={usersState.users}
          numberOfKpis={kpiItemList.length}
          close={closeModal}
          deviceType={device}
          getReportStatus={getReportStatus}
          updateFile={handleUpdateFile}
        />
      ),
    });
  };

  const getFileColumns = async (database) => {
    const res = await filesAPI.getColumns(database);
    if (res.error) {
      setAlertConfig({ type: 'error', text: error.generic, child: res.msg });
      return [];
    } if (res.columns) {
      return Object.keys(res.columns).map((val) => ({
        label: val, value: val, id: val, type: getType(res.columns[val]),
      })) ?? [];
    }
    return [];
  };

  const getDependencyColumns = async (database) => {
    const opts = await getFileColumns(database);
    setDashFiles((files) => files.map((f) => {
      if (f.file_id === database) f.columns = opts;
      return f;
    }));
    return opts;
  };

  const handleGlobalFilterSelectors = async (selectedGFilters, selectedGLimits) => {
    const result = await gridKpiAPI.setGlobalFilterSelectors(selectedGFilters, selectedGLimits);
    if (result.error) {
      setAlertConfig({
        type: 'error',
        text: result.msg,
        child: `${result.raw}`,
      });
      return;
    }
    setAlertConfig({
      type: 'success',
      text: 'Colunas de filtro global editadas com sucesso!',
    });
  };

  const filterColumnsModal = (toolbarStateSetter) => {
    const getColumnUnique = async (database, column, selector) => {
      const res = await filesAPI.getUnique(database, column, selector);
      if (res.error) {
        setAlertConfig({ type: 'error', text: error.generic, child: res.msg });
        return [];
      }
      return res.res.map((val) => ({ label: val, value: val, id: val }));
    };

    setModalConfig({
      title: 'Filtro global',
      children: (
        <GlobalFiltersColumns
          usedFiles={dashFiles}
          getFileColumns={getDependencyColumns}
          getUnique={getColumnUnique}
          hide={closeModal}
          globalFiltersState={[globalFilterSelectors, setGlobalFilterSelectors]}
          globalFilterLimitsState={[globalFilterLimits, setGlobalFilterLimits]}
          showToolbar={toolbarStateSetter}
          submit={handleGlobalFilterSelectors}
        />
      ),
    });
  };

  const checkAccessToDependency = (kpi) => {
    const dependency = dashFiles.find((file) => file.file_id === kpi.database);
    return dependency;
  };

  const checkUserWritePermission = (kpi) => {
    const dependency = dashFiles.find((file) => file.file_id === kpi.database);
    if (dependency?.shared_write) {
      return dependency?.shared_write.includes(currentUser.uid);
    }
    return false;
  };

  const getKpiWithoutDependencyMessage = (dependency, kpi) => {
    const originalFile = filesState.instanceFiles.find((file) => file.file_id === kpi.database);
    if (!originalFile) {
      setAlertConfig({
        type: 'error',
        text: 'Dependência não existe!',
        child: (
          <div style={{ fontSize: '14px' }}>
            O arquivo utilizado na construção deste KPI foi apagado.
            Portanto, este KPI não pode ser atualizado!
          </div>
        ),
      });
      return;
    }
    const currentOwner = usersState.users.find((u) => u.uid === dependency.owner);
    setAlertConfig({
      type: 'warning',
      text: 'Você não possui acesso ao arquivo de origem!',
      child: (
        <>
          <div style={{ fontSize: '14px' }}>
            Você não possui permissão de acesso ao arquivo
            {' '}
            <b style={{ color: theme.warning }}>{dependency.filename}</b>
            {' '}
            de propriedade do(a) usuário(a)
            {' '}
            <b style={{ color: theme.warning }}>{currentOwner.name || currentOwner.email.split('@')[0]}</b>
            .
          </div>
          <div style={{ fontSize: '14px' }}>
            Para atualizar este KPI, solicite o compartilhamento do arquivo
            {' '}
            <b style={{ color: theme.warning }}>{dependency.filename}</b>
            {' '}
            ao proprietário.
          </div>
        </>
      ),
    });
  };

  const handleExportDashboard = async () => {
    const result = await gridKpiAPI.exportDashboard(dashboardDoc.id, dashboardDoc?.name || 'Dashboard');
    if (result.error) {
      setAlertConfig({
        type: 'error',
        text: result.msg,
        child: `${result.raw}`,
      });
      return;
    }
    setAlertConfig({
      type: 'success',
      text: 'Dashboard exportado com sucesso!',
    });
  };

  const handleIframe = (item) => {
    const submitIframe = async (editedItem) => {
      const LabelObject = {
        name: '',
        link: editedItem.link,
        linkType: editedItem.linkType,
        status: 'active',
        type: 'Iframe',
        style: {},
      };
      const result = await gridKpiAPI.saveInsightCard(
        kpisLayout, editedItem.id, LabelObject, '',
      );
      if (result.error) {
        setAlertConfig({
          type: 'error',
          text: `Erro ao tentar ${item ? 'editar' : 'adicionar'} o conteúdo externo`,
        });
        return false;
      }
      gridKpiAPI.loadCardsFromCollection();
      setAlertConfig({
        type: 'success',
        text: `Conteúdo externo ${item ? 'editado' : 'adicionado'} com sucesso!`,
      });
      return true;
    };

    const handlePlotlyLink = async (plotlyId) => {
      const plotlyRes = await gridKpiAPI.getPlotlyLink(plotlyId);
      if (plotlyRes.error) return null;
      return plotlyRes.link;
    };

    const plan = userCompany?.plan || 'advanced';
    const companyPlan = plans[plan];
    const externalKpis = userCompany?.externalKpis || companyPlan.externalKpis;
    if (!externalKpis) {
      setAlertConfig({
        type: 'warning',
        text: 'Permissões insuficientes',
        child: 'Seu plano não permite adicionar conteúdos externos. Para alterar o plano, entre em contato com o suporte.',
      });
      return;
    }
    setModalConfig({
      title: `${item ? 'Editar' : 'Adicionar'} Outros Conteúdos`,
      className: 'modal_iframe',
      children: (
        <IframeCreate
          item={item}
          hide={closeModal}
          submit={submitIframe}
          stylesData={dashboardDoc.cardStyles}
          getPlotlyLink={handlePlotlyLink}
        />
      ),
    });
  };

  const handleSubsetTable = (item) => {
    const hideSubsetTableCreate = () => closeModal('subsettable-create');

    const filesOpts = filesState.files?.map((val) => ({
      label: val.filename.replace('.metrics', ''),
      value: val.file_id,
      id: val.file_id || val.filename,
      owner: val.owner,
    })) ?? [];

    const genTable = async (metaInfo, fileId, page, sortBy, sortOrder) => {
      const res = await kpiDataAPI.genSubsetData(metaInfo, fileId, page, sortBy, sortOrder);
      if (res.error) {
        setAlertConfig({
          type: 'error',
          text: 'Algo está incorreto!',
          child: 'Os elementos selecionados não permitem a criação da Tabela',
        });
        return null;
      }
      return res;
    };

    const getColumnUnique = async (fileId, column, dFormat) => {
      const dateFormat = dFormat?.value ?? '';
      const uniqueRes = await filesAPI.getUnique(fileId, column.value ?? column, dateFormat);
      if (uniqueRes.error) {
        setAlertConfig({ type: 'error', text: error.generic, child: uniqueRes.msg });
        return [];
      }
      return uniqueRes.res.map((val) => ({ label: val, value: val, id: val }));
    };

    const submitTable = async (title, newItem, newStyleConfig) => {
      const CHARTTYPE = 'SubsetTable';
      const rawSC = {
        ...newStyleConfig,
        TitleControl: title,
        type: CHARTTYPE,
      };
      const defaultSC = getDefaultStyle(CHARTTYPE);
      /** List of keys to keep */
      const alwaysKeep = [
        'GlobalValue', 'ShowTitleControl', 'StyleTitleControl', 'type', 'TitleControl', 'TotalFunctionControl',
      ];
      // Remove attributes that have not changed from the default
      const tmp = Object.entries(rawSC).reduce((aux, [k, v]) => {
        if (alwaysKeep.includes(k)) aux[k] = v;
        else if (defaultSC[k] !== undefined && !isEqual(v, defaultSC[k])) aux[k] = v;
        return aux;
      }, {});

      try {
        const metaGridInfo = newItem.id !== 'new' ? (
          kpisLayout?.[gridBreakpoint]?.find((l) => l.i === newItem.id) ?? {}
        ) : {};
        if (!item && subsetTableReachedLimit()) {
          const subsetTableLimit = (
            userCompany?.subsetTableLimit
            ?? plans?.[userCompany?.plan || 'advanced']?.subsetTableLimit
          );
          setAlertConfig({
            type: 'warning',
            text: 'Limite de Subtabelas atingido!',
            child: `Seu plano permite ${subsetTableLimit} Subtabela${subsetTableLimit > 1 ? 's' : ''} por Dashboard. Para alterar o plano, entre em contato com o suporte.`,
          });
          return;
        }
        const res = await gridKpiAPI.saveKpiCard(
          title, newItem.id, CHARTTYPE, kpisLayout, newItem.meta, metaGridInfo, {}, tmp,
        );
        if (res.error) {
          setAlertConfig({
            type: 'error',
            text: res.msg,
          });
          return;
        }
        hideSubsetTableCreate();
        gridKpiAPI.loadCardsFromCollection();
        setAlertConfig({
          type: 'success',
          text: `Subtabela ${item ? 'editada' : 'adicionada'} com sucesso!`,
        });
      } catch (er) {
        console.log(er);
      }
    };

    const haveTitleModal = (title, newItem, newStyleConfig) => {
      setModalConfig({
        title: 'Salvar Subtabela',
        notLabel: 'Cancelar',
        yesLabel: 'Salvar',
        yesFunc: () => submitTable(title, newItem, newStyleConfig),
        confirmClassName: 'submit_name_btn',
        children: (
          <div style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
          }}
          >
            <span style={{ fontSize: '1.2rem', padding: '10px 0px' }}>
              Tudo pronto para salvar a Subtabela:
              {' '}
              <b>
                {title}
              </b>
            </span>
            <span>Tem certeza que deseja continuar?</span>
          </div>
        ),
      });
    };

    const notHaveTitleModal = (newItem, newStyleConfig) => {
      const onSubmit = (newTitle) => {
        haveTitleModal(newTitle, newItem, newStyleConfig);
      };

      setModalConfig({
        title: 'Salvar Subtabela',
        className: 'title_modal',
        children: (
          <TitleModal submit={onSubmit} />
        ),
      });
    };

    const handleSubmitTable = (newItem, newStyleConfig) => {
      if (newStyleConfig.TitleControl) {
        haveTitleModal(newStyleConfig.TitleControl, newItem, newStyleConfig);
      } else notHaveTitleModal(newItem, newStyleConfig);
    };

    setModalConfig({
      title: `${item ? 'Editar' : 'Adicionar'} Subtabela`,
      className: 'modal_iframe',
      disableFocus: true,
      nodeTarget: 'subsettable-create',
      children: (
        <SubsetTableCreate
          item={item}
          hide={hideSubsetTableCreate}
          submit={handleSubmitTable}
          stylesData={dashboardDoc.cardStyles}
          filesOpts={filesOpts}
          getFileColumns={getFileColumns}
          genTable={genTable}
          userId={currentUser.uid}
          getColumnUnique={getColumnUnique}
        />
      ),
    });
  };

  return (
    <div>
      {(isLoading || gridAPILoading || manualLoad || filesState.isLoading) && (
        <Loader underText={manualLoad === 'print' ? printLoadMsg : ''} />
      )}
      <GridKpisToolbar
        isOwner={isOwner}
        isAdmin={claimsUser?.is_admin}
        isGuest={user.role === 'guest'}
        dashboardKeyValid={dashboardKeyValid}
        gridKpiState={gridKpiState}
        gridKpiAPI={gridKpiAPI}
        editPainel={editPainel}
        setEditPainel={setEditPainel}
        goBack={() => history.push('/home')}
        openMural={() => setOpenMural(true)}
        openSaveAsTemplate={handleCreateTemplate}
        // openShare={() => setOpenShare(true)}
        openShare={handleShare}
        // snaps
        handleCreateSnapshot={handleCreateSnapshot}
        handleEditSnapshot={handleEditSnapshot}
        handleDeleteSnapshot={handleDeleteSnapshot}
        changeCurrentSnap={changeCurrentSnap}
        handleScheduleSnapshot={handleScheduleSnapshot}
        //
        redirectToInsightCreation={redirectToInsightCreation}
        redirectToKpiCreation={redirectToKpiCreation}
        // card Styles
        handleCardStyles={handleCardStyles}
        //
        printFunction={handlePrint}
        gridBreakpoint={gridBreakpoint}
        showToolbar={showToolbar}
        handleShowToolbar={handleShowToolbar}
        getDependencies={handleDependencies}
        handleFullScreen={handleFullScreen}
        handleExportDashboard={handleExportDashboard}
        // Global Filters
        fileDependencies={dashFiles}
        globalFilters={globalFilters}
        globalFiltersOrder={globalFiltersOrder}
        globalFilterSelectors={globalFilterSelectors}
        setGlobalFilterSelectors={setGlobalFilterSelectors}
        getColumnValues={getControlValues}
        filterColumnsModal={filterColumnsModal}
        //
        handleIframe={handleIframe}
        handleSubsetTable={handleSubsetTable}
      />
      {kpiItemList.length > 0 ? (
        <GridKpis
          gridKpiRef={gridKpiRef}
          isOwner={isOwner}
          isSuper={user.role === 'super'}
          dashboardFileDependencies={dashFiles}
          editPainel={editPainel}
          showToPrint={showToPrint}
          dashboardKey={dashboardKeyValid}
          saveLayout={handleSaveLayout}
          //
          kpiItemList={kpiItemList}
          dashboardDoc={dashboardDoc}
          kpisLayout={kpisLayout}
          //
          gridKpiAPI={gridKpiAPI}
          gridKpiState={gridKpiState}
          dashboards={dashboards.filter((dashboard) => (
            dashboard.owner === currentUser.uid
            && dashboard.id !== dashboardDoc.id
            && !dashboard?.isDeleted
          ))}
          gridBreakpoint={gridBreakpoint}
          setGridBreakpoint={setGridBreakpoint}
          showToolbar={showToolbar}
          //
          getControlValues={getControlValues}
          handleDynamicKpi={handleDynamicKpi}
          checkAccessToDependency={checkAccessToDependency}
          checkUserWritePermission={checkUserWritePermission}
          getKpiWithoutDependencyMessage={getKpiWithoutDependencyMessage}
          fullScreenHandler={handle}
          handleIframe={handleIframe}
          handleSubsetTable={handleSubsetTable}
          handleValueModifiers={handleValueModifiers}
          //
          globalFilters={globalFilters}
          globalFiltersOrder={globalFiltersOrder}
          getUserName={getUserName}
          subsetTableReachedLimit={subsetTableReachedLimit}
          subsetTableLimit={(
            userCompany?.subsetTableLimit
            ?? plans?.[userCompany?.plan || 'advanced']?.subsetTableLimit
          )}
          plan={userCompany?.plan ?? 'advanced'}
        />
      ) : !isLoading && (
        <NoKpi
          dashboardKey={dashboardKeyValid}
          isShared={userId !== currentUser.uid}
          role={user.role}
        />
      )}
      <Mural
        open={openMural}
        close={closeMural}
        users={getUsersShareds()}
        currentUser={currentUser}
        kpiState={gridKpiState}
        // loaders
        loadComments={gridKpiAPI.loadComments}
        loadMoreComments={gridKpiAPI.loadMoreComments}
        // actions
        addComment={gridKpiAPI.addComment}
        editComment={gridKpiAPI.editComment}
        archiveComment={handleArchiveComment}
      />
    </div>
  );
}
