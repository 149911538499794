import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import Button from '../../../juristec-ui/core/Button';
import TextArea from '../../../juristec-ui/core/TextArea';
import InputTextLine from '../../../juristec-ui/core/InputTextLine';
import Select from '../../../juristec-ui/core/SelectNew';

import { verifyInput, verifyLink } from '../../../juristec-ui/utils/validators/inputTextValidators';
import { trimString } from '../../../juristec-ui/utils/functions/formatString';

import {
  MainContainer,
  GridContainer,
  InstanceListContainer,
  InstanceList,
  TextAreaWrapper,
  OptionGroup,
  Option,
} from './styled/AllUsersMessage.styled';
import DatePicker from '../../../juristec-ui/core/DatePicker';

const labelTitle = {
  info: 'AVISO',
  warning: 'ATENÇÃO',
  error: 'ERRO',
};

const AllUsersMessage = ({
  hide, submit, instances,
}) => {
  const [msg, setMsg] = useState({ error: true, errorMsg: '', value: '' });
  const [title, setTitle] = useState({ error: true, errorMsg: '', value: '' });
  const [link, setLink] = useState({ error: true, errorMsg: '', value: '' });
  const [linkMask, setLinkMask] = useState({ error: true, errorMsg: '', value: '' });
  const [selected, setSelected] = useState({
    premium: [], advanced: [], standard: [], others: [],
  });
  const [msgLvl, setMsgLvl] = useState('info');

  const [showUntil, setShowUntil] = useState('');

  const handleMsg = (val) => {
    const errMsg = verifyInput(val, true, 2, 500);
    setMsg({
      value: val,
      error: errMsg.length !== 0,
      errorMsg: errMsg,
    });
  };

  const handleMsgEvent = (e) => {
    const val = e.target.value;
    handleMsg(val);
  };

  const msgTrim = () => {
    handleMsg(trimString(msg.value));
  };

  const handleTitle = (val) => {
    const errMsg = verifyInput(val, false, 0, 50);
    setTitle({
      value: val,
      error: errMsg.length !== 0,
      errorMsg: errMsg,
    });
  };

  const handleTitleEvent = (e) => {
    const val = e.target.value;
    handleTitle(val);
  };

  const titleTrim = () => {
    handleTitle(trimString(title.value));
  };

  const handleLink = (e) => {
    const val = e.target.value?.trim() || '';
    const errMsg = verifyLink(val);
    setLink({
      value: val,
      error: errMsg.length !== 0,
      errorMsg: errMsg,
    });
  };

  const handleLinkMask = (val) => {
    const errMsg = verifyInput(val, false, 0, 50);
    setLinkMask({
      value: val,
      error: errMsg.length !== 0,
      errorMsg: errMsg,
    });
  };

  const handleLinkMaskEvent = (e) => {
    const val = e.target.value;
    handleLinkMask(val);
  };

  const linkMaskTrim = () => {
    handleLinkMask(trimString(linkMask.value));
  };

  const handleSelection = (selectedInstance, lvl) => {
    setSelected((o) => ({
      ...o,
      [lvl]: selectedInstance,
    }));
  };

  const blockSubmit = () => (
    msg.error
    || msg.value?.length <= 0
    || (
      selected.premium?.length <= 0
      && selected.advanced?.length <= 0
      && selected.standard?.length <= 0
    )
  );

  const showUntilDate = showUntil ? new Date(showUntil.setDate(showUntil.getDate() + 1)) : null;

  const handleSubmit = () => {
    submit(
      msgLvl,
      title.value?.length > 0 && !title.error ? title.value : labelTitle[msgLvl],
      showUntilDate,
      link.value?.length > 0 && !link.error ? {
        [linkMask.value || 'Link externo']:
          link.value.startsWith('https://') ? link.value : `https://${link.value}`,
      } : null,
      [
        ...selected.premium, ...selected.advanced, ...selected.standard, ...selected.others,
      ].map((instance) => instance?.id),
      msg.value,
    );
  };

  return (
    <MainContainer>
      <GridContainer>
        <InstanceListContainer>
          <InstanceList>
            {instances?.standard?.length > 0 && (
              <Select
                label="Standard"
                options={instances.standard}
                placement="start"
                value={selected?.standard}
                onChange={(change) => handleSelection(change, 'standard')}
                multiple
                stayOpen
                atModal
                selectAll
                sortBy="ascending"
                searchable
              />
            )}
            {instances?.advanced?.length > 0 && (
              <Select
                label="Advanced"
                options={instances?.advanced ?? []}
                placement="start"
                value={selected?.advanced}
                onChange={(change) => handleSelection(change, 'advanced')}
                multiple
                stayOpen
                atModal
                selectAll
                sortBy="ascending"
                searchable
              />
            )}
            {instances?.premium?.length > 0 && (
              <Select
                label="Premium"
                options={instances?.premium ?? []}
                placement="start"
                value={selected?.premium}
                onChange={(change) => handleSelection(change, 'premium')}
                multiple
                stayOpen
                atModal
                selectAll
                sortBy="ascending"
                searchable
              />
            )}
            {instances?.others?.length > 0 && (
              <Select
                label="Outras"
                options={instances?.others ?? []}
                placement="start"
                value={selected?.others}
                onChange={(change) => handleSelection(change, 'others')}
                multiple
                stayOpen
                atModal
                selectAll
                sortBy="ascending"
                searchable
              />
            )}
          </InstanceList>
        </InstanceListContainer>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <OptionGroup>
            <Option
              value="info"
              onClick={() => setMsgLvl('info')}
              active={msgLvl === 'info'}
            >
              Aviso
            </Option>
            <Option
              value="warning"
              onClick={() => setMsgLvl('warning')}
              active={msgLvl === 'warning'}
            >
              Atenção
            </Option>
            <Option
              value="error"
              onClick={() => setMsgLvl('error')}
              active={msgLvl === 'error'}
            >
              Erro
            </Option>
          </OptionGroup>
          <div style={{ margin: '.2rem 0 .5rem' }}>
            <InputTextLine
              label="Título (opcional)"
              error={title.errorMsg.length > 0}
              errorMessage={title.errorMsg}
              value={title.value}
              onChange={handleTitleEvent}
              onBlur={titleTrim}
            />
          </div>
          <div style={{ margin: '.2rem 0 .5rem' }}>
            <DatePicker
              value={showUntil}
              onChange={setShowUntil}
              label="Mensagem disponível até (opcional)"
              style={{ width: '50%' }}
              readOnly={false}
              dateFormat="dd/MM/yyyy"
              atModal
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', margin: '.2rem 0 .5rem' }}>
            <InputTextLine
              label="Link externo (opcional)"
              error={link.errorMsg.length > 0}
              errorMessage={link.errorMsg}
              value={link.value}
              onChange={handleLink}
              wrapperStyle={{ marginRight: '.5rem' }}
            />
            <InputTextLine
              label="Máscara do link (opcional)"
              error={linkMask.errorMsg.length > 0}
              errorMessage={linkMask.errorMsg}
              value={linkMask.value}
              onChange={handleLinkMaskEvent}
              onBlur={linkMaskTrim}
            />
          </div>
          <TextAreaWrapper>
            <TextArea
              label="Mensagem*"
              name="Mensagem"
              value={msg.value}
              error={msg.errorMsg.length > 0}
              errorMessage={msg.errorMsg}
              onChange={handleMsgEvent}
              onBlur={msgTrim}
            />
          </TextAreaWrapper>
        </div>
      </GridContainer>
      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={hide}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button
          style={{ margin: '5px' }}
          size="large"
          disabled={blockSubmit()}
          onClick={handleSubmit}
        >
          Enviar
        </Button>
      </ActionsGroup>
    </MainContainer>
  );
};

AllUsersMessage.propTypes = {
  hide: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  instances: PropTypes.shape({
    premium: PropTypes.arrayOf(PropTypes.shape({})),
    advanced: PropTypes.arrayOf(PropTypes.shape({})),
    standard: PropTypes.arrayOf(PropTypes.shape({})),
    others: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

AllUsersMessage.defaultProps = {
  instances: {
    premium: [],
    advanced: [],
    standard: [],
    others: [],
  },
};

export default AllUsersMessage;
