import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Select from '../../../juristec-ui/core/Select';
import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import Button from '../../../juristec-ui/core/Button';

import { MainContainer, InfoText, WarningMessage } from './styled/TransferUser.styled';

/** Transfers all allowed data from one user to another */
const TransferUser = ({
  selectedUser, usersOptions, close, submit, viewerOnly,
}) => {
  const [selected, setSelected] = useState({ value: '', label: '' });

  const handleSubmit = () => {
    submit(selected);
  };

  return (
    <MainContainer>
      <InfoText>
        Selecione o usuário para o qual você deseja transferir os arquivos e dashboards de
        {' '}
        <b>
          {selectedUser.name}
        </b>
      </InfoText>
      {viewerOnly ? (
        <span style={{ fontSize: '10pt', marginBottom: '10px' }}>
          <WarningMessage>
            <strong>Atenção: </strong>
          </WarningMessage>
          O usuário original manterá a propriedade sobre os arquivos e manterá os seus dashboards
        </span>
      ) : (
        <span style={{ fontSize: '10pt', marginBottom: '10px' }}>
          <WarningMessage>
            <strong>Atenção: </strong>
          </WarningMessage>
          Os arquivos e dashboards serão transferidos para o novo usuário e o usuário original
          perderá a propriedade em ambos os casos.
        </span>
      )}
      <Select
        selectLabel="Usuários"
        value={selected}
        onChange={setSelected}
        options={usersOptions}
        fullWidth
        atModal
      />
      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={close}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button
          style={{ margin: '5px' }}
          onClick={handleSubmit}
          size="large"
          disabled={!selected.value}
        >
          Transferir
        </Button>
      </ActionsGroup>
    </MainContainer>
  );
};

TransferUser.propTypes = {
  selectedUser: PropTypes.shape({
    name: PropTypes.string,
  }),
  usersOptions: PropTypes.arrayOf(PropTypes.shape({})),
  close: PropTypes.func,
  submit: PropTypes.func,
  viewerOnly: PropTypes.bool,
};

TransferUser.defaultProps = {
  selectedUser: {
    name: '',
  },
  usersOptions: [],
  close: () => {},
  submit: () => {},
  viewerOnly: false,
};

export default TransferUser;
