import styled, { css } from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  & > * + * {
    margin-top: 10px;
  }
`;

export const BlockLabel = styled.span`
  font-size: 11px;
`;

export const InputRow = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  &.date-picker .inputTxt {
    caret-color: transparent;
  }
  ${({ disabled }) => disabled && css`
    cursor: not-allowed;
    & > * {
      opacity: 0.3;
    }
    & * {
      pointer-events: none !important;
    }
  `}
`;
