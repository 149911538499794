import styled from 'styled-components';
import { devices } from '../../../juristec-ui/core/breakpoints';

export const Container = styled.div`
  padding: 0 .5rem;
  font-weight: 500;

  & .link-title { 
    font-size: 0.85rem;
    font-weight: bold;
    color: ${({ theme }) => theme.highlightTextLine};
  }
  & .empty-link {
    color: ${({ theme }) => theme.grey};
    font-size: 12px;
  }
`;

export const ContentButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.container};
  cursor: pointer;
  border: 2px solid ${({ theme }) => theme.containerHover};
  &:hover {
    background-color: ${({ theme }) => theme.containerHover};
  }
  & svg {
    width: 20px;
    height: 20px;
    margin: 0 auto;
    fill: ${({ theme }) => theme.primary};
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin: 5px 0;
  & .expired-link {
    color: ${({ theme }) => theme.error};
    font-size: 12px;
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 415px;
  margin: 5px 0 5px 5px;
  & span {
    color: ${({ theme }) => theme.color};
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
    width: 100%;
    margin: 0;
  }
  @media ${devices.phoneS} {
    max-width: calc(100vw - 102px);
  }
`;

export const Timestamp = styled.span`
  color: ${({ theme }) => theme.grey};
  font-size: 12px;
  width: 100%;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  & > * + * {
    margin-left: 5px;
  }
  & .action-button {
    padding: 2px 5px 2px 10px;
    & svg {
      width: 20px;
      height: 20px;
      margin: 0 0 0 5px;
      box-shadow: none;
    }
  }
`;
