import React, {
  useState, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import StepProgress from '../../../juristec-ui/core/StepProgress';
import FirstStep from './Steps/FirstStep';
import SecondStep from './Steps/SecondStep';
import ThirdStep from './Steps/ThirdStep';
import FourthStep from './Steps/FourthStep';

const NewInstance = ({
  closeModal,
  submitNewInstance,
  isEdit,
  toEditInstance,
  submitEditInstance,
  plans,
}) => {
  // Configs da instancia
  const [companyName, setCompanyName] = useState({ value: '', error: true, errorMsg: '' });
  const [companyUrl, setCompanyUrl] = useState({ value: '', error: false, errorMsg: '' });
  const [selectedPlan, setSelectedPlan] = useState({ value: '', label: '' });
  const [publicLink, setPublicLink] = useState(true);
  const [multifactor, setMultifactor] = useState(false);
  const [externalIdentifier, setExternalIdentifier] = useState({ value: '', error: false, errorMsg: '' });
  const [createL1Report, setCreateL1Report] = useState(false);
  const [operationalStatus, setOperationalStatus] = useState({ value: '', label: '' });
  const [bigData, setBigData] = useState(false);
  const [gptApi, setGptApi] = useState(false);

  // Configs Operacionais
  const [contactName, setContactName] = useState({ value: '', error: false, errorMsg: '' });
  const [contactEmail, setContactEmail] = useState({ value: '', error: false, errorMsg: '' });
  const [contactPhone, setContactPhone] = useState({
    value: '', error: false, errorMsg: '', format: '',
  });
  const [obs, setObs] = useState({ value: '' });
  const [training, setTraining] = useState(false);
  const [trainingDate, setTrainingDate] = useState(new Date());
  const [trainingResponsible, setTrainingResponsible] = useState({ value: '', error: false, errorMsg: '' });

  // var q são guardadas mas n tem input/select para modificá-las.
  const support = useRef(null);
  const templates = useRef([]);
  const image = useRef({});
  const [dashboardLimit, setDashboardLimit] = useState(100000);

  // Configs de plano
  const [volumeData, setVolumeData] = useState(0);
  const [scientistLimit, setScientistLimit] = useState(0);
  const [adminLimit, setAdminLimit] = useState(0);
  const [guestLimit, setGuestLimit] = useState(0);
  const [snapsLimit, setSnapsLimit] = useState(0);

  // Limites de plano
  const [publicLinkPwd, setPublicLinkPwd] = useState(false);
  const [scheduledSnapshotAllowed, setScheduledSnapshotAllowed] = useState(false);
  const [maxHistory, setMaxHistory] = useState(4);
  const [advancedCharts, setAdvancedCharts] = useState(false);
  const [externalKpis, setExternalKpis] = useState(false);
  const [publicLinkExpirationDays, setPublicLinkExpirationDays] = useState(2);
  const [subsetTableLimit, setSubsetTableLimit] = useState(1);
  const [scheduledHistoryAllowed, setScheduledHistoryAllowed] = useState(false);
  // Atualizações
  const [attemptsPerHour, setAttemptsPerHour] = useState(3);
  // const [globalFilterLimit, setGlobalFilterLimit] = useState(3);
  // const [snapshotsAllowed, setSnapshotsAllowed] = useState(true);
  // const [historyAllowed, setHistoryAllowed] = useState(true);
  // const [descriptiveCharts, setDescriptiveCharts] = useState(true);

  // Configs do contrato
  const [signatureDate, setSignatureDate] = useState(new Date());
  const [operationalDate, setOperationalDate] = useState(new Date());
  const [projectValue, setProjectValue] = useState(359);
  const [installationPrice, setInstallationPrice] = useState(359);
  const [connectIntegration, setConnectIntegration] = useState({ value: '', error: false, errorMsg: '' });
  const [reportAmount, setReportAmount] = useState(0);
  const [consulting, setConsulting] = useState(false);
  const [parameterizedDash, setParameterizedDash] = useState(2);
  const [customDash, setCustomDash] = useState(2);

  // Configs do push
  const [hasPush, setHasPush] = useState(false);
  const [antecipeiValue, setAntecipeiValue] = useState(0);
  const [freeMonitoring, setFreeMonitoringLimit] = useState(0);
  const [monitoringPrice, setMonitoringPrice] = useState(0);
  const [newProcessPrice, setNewProcessPrice] = useState(0);
  const [oldProcessPrice, setOldProcessPrice] = useState(0);
  const [numberOfCompanies, setNumberOfCompanies] = useState(0);
  const [frequency, setFrequency] = useState({ value: '', label: '' });
  const [courts, setCourts] = useState({ value: '', label: '' });
  const [weekDayAntecipei, setWeekDayAntecipei] = useState({ value: '', label: '' });
  const [limitLegalSuits, setLimitLegalSuits] = useState(0);
  const [aditionalLegalSuitPrice, setAditionalLegalSuitPrice] = useState(0);

  // Emails
  const [scientistsEmails, setScientistsEmails] = useState([]);
  const [adminEmails, setAdminEmails] = useState([]);
  const [sendEmail, setSendEmail] = useState(false);

  // Configs da instancia a ser editada
  useEffect(() => {
    if (isEdit) {
      setCompanyName((old) => ({ ...old, value: toEditInstance.name, error: false }));
      setCompanyUrl((old) => ({ ...old, value: toEditInstance.companyurl }));
      setSelectedPlan({ value: toEditInstance.plan, label: toEditInstance.plan });
      setExternalIdentifier((old) => ({ ...old, value: toEditInstance.external_identifier }));
      setOperationalStatus({
        value: toEditInstance.operationalStatus,
        label: toEditInstance.operationalStatus,
      });
      setDashboardLimit(toEditInstance.dashboards);
      setVolumeData(toEditInstance.volumeData);
      setScientistLimit(toEditInstance.scientists);
      setAdminLimit(toEditInstance.superS);
      setGuestLimit(toEditInstance.guests);
      setSnapsLimit(toEditInstance.snapshots);
      setPublicLink(toEditInstance.publicLink);
      setCreateL1Report(toEditInstance.l1reportActive);
      setMultifactor(toEditInstance.multifactor);
      setBigData(toEditInstance.bigData);
      setGptApi(toEditInstance.gptApi);
      // Antecipei
      setHasPush(toEditInstance.push);
      setFreeMonitoringLimit(toEditInstance.monitoring || 0);
      setMonitoringPrice(toEditInstance.newMonitoring || 0);
      setNewProcessPrice(toEditInstance.newProcess || 0);
      setOldProcessPrice(toEditInstance.oldProcess || 0);
      setNumberOfCompanies(toEditInstance?.numberOfCompanies || 0);
      setFrequency({
        value: toEditInstance?.frequency || '',
        label: toEditInstance?.frequency || '',
      });
      setCourts({
        value: toEditInstance?.courts || '',
        label: toEditInstance?.courts || '',
      });
      setWeekDayAntecipei({
        value: toEditInstance?.weekDayAntecipei || '',
        label: toEditInstance?.weekDayAntecipei || '',
      });
      setLimitLegalSuits(toEditInstance?.limitLegalSuits || 0);
      setAditionalLegalSuitPrice(toEditInstance?.aditionalLegalSuitPrice || 0);
      // Contrato
      setSignatureDate(toEditInstance?.signatureDate ? new Date(toEditInstance?.signatureDate) : new Date('1900-01-01T00:00'));
      setOperationalDate(toEditInstance?.operationalDate ? new Date(toEditInstance?.operationalDate) : new Date('1900-01-01T00:00'));
      setProjectValue(toEditInstance?.projectValue || 0);
      setInstallationPrice(toEditInstance?.installationPrice || 0);
      setAntecipeiValue(toEditInstance?.antecipeiValue || 0);
      setConnectIntegration((old) => ({ ...old, value: toEditInstance?.connectIntegration || '' }));
      setReportAmount(toEditInstance?.reportAmount || 0);
      setConsulting(toEditInstance?.consulting || false);
      setParameterizedDash(toEditInstance?.parameterizedDash || 0);
      setCustomDash(toEditInstance?.customDash || 0);
      // Operacional
      setContactName((old) => ({
        ...old,
        value: toEditInstance.contactName || '',
      }));
      setContactEmail((old) => ({
        ...old,
        value: toEditInstance.contactEmail || '',
      }));
      setContactPhone((old) => ({
        ...old,
        value: toEditInstance.contactPhone || '',
      }));
      setObs((old) => ({
        ...old,
        value: toEditInstance.obs || '',
      }));
      setTraining(toEditInstance?.training || false);
      setTrainingDate(toEditInstance?.trainingDate ? new Date(toEditInstance?.trainingDate) : new Date('1900-01-01T00:00'));
      setTrainingResponsible((old) => ({
        ...old,
        value: toEditInstance?.trainingResponsible || '',
      }));
      // Limites de plano
      setAttemptsPerHour(
        toEditInstance?.attemptsPerHour ?? plans[toEditInstance.plan].attemptsPerHour,
      );
      setPublicLinkPwd(
        toEditInstance.publicLinkPwd ?? plans[toEditInstance.plan].publicLinkPwd,
      );
      setScheduledSnapshotAllowed(
        toEditInstance.scheduledSnapshotAllowed ?? plans[toEditInstance.plan].scheduledSnapshotAllowed,
      );
      setMaxHistory(
        toEditInstance.maxHistory ?? plans[toEditInstance.plan].maxHistory,
      );
      setAdvancedCharts(
        toEditInstance.advancedCharts ?? plans[toEditInstance.plan].advancedCharts,
      );
      setExternalKpis(
        toEditInstance.externalKpis ?? plans[toEditInstance.plan].externalKpis,
      );
      setPublicLinkExpirationDays(
        toEditInstance.publicLinkExpirationDays ?? plans[toEditInstance.plan].publicLinkExpirationDays,
      );
      setSubsetTableLimit(
        toEditInstance.subsetTableLimit ?? plans[toEditInstance.plan].subsetTableLimit,
      );
      setScheduledHistoryAllowed(
        toEditInstance.scheduledHistoryAllowed ?? plans[toEditInstance.plan].scheduledHistoryAllowed,
      );
      //
      image.current = toEditInstance.photoUrl;
      templates.current = toEditInstance.templates;
      support.current = toEditInstance.support;
    }
  }, [isEdit, toEditInstance]);

  /**
   * Funcao que checa se o primeiro passo pode avancar ou nao
   * @returns booleano que, se verdadeiro, nao possui erros e pode avancar
   */
  const checkFirstForm = () => (
    !companyName.error && selectedPlan?.value.length > 0 && adminLimit > 0 && !companyUrl.error
  );

  /**
   * Primeiro passo, configs da instancia
   */
  const firstStep = {
    label: 'Contrato',
    validation: checkFirstForm,
    content: (
      <FirstStep
        companyNameState={[companyName, setCompanyName]}
        companyUrlState={[companyUrl, setCompanyUrl]}
        planState={[selectedPlan, setSelectedPlan]}
        externalIdentifierState={[externalIdentifier, setExternalIdentifier]}
        createL1ReportState={[createL1Report, setCreateL1Report]}
        operationalStatusState={[operationalStatus, setOperationalStatus]}
        dashboardsState={[dashboardLimit, setDashboardLimit]}
        publicLinkState={[publicLink, setPublicLink]}
        multifactorState={[multifactor, setMultifactor]}
        hasPushState={[hasPush, setHasPush]}
        volumeDataState={[volumeData, setVolumeData]}
        scientistLimitState={[scientistLimit, setScientistLimit]}
        adminLimitState={[adminLimit, setAdminLimit]}
        guestLimitState={[guestLimit, setGuestLimit]}
        snapsLimitState={[snapsLimit, setSnapsLimit]}
        freeMonitoringState={[freeMonitoring, setFreeMonitoringLimit]}
        monitoringPriceState={[monitoringPrice, setMonitoringPrice]}
        newProcessPriceState={[newProcessPrice, setNewProcessPrice]}
        oldProcessPriceState={[oldProcessPrice, setOldProcessPrice]}
        bigDataState={[bigData, setBigData]}
        gptApiState={[gptApi, setGptApi]}
        signatureDateState={[signatureDate, setSignatureDate]}
        operationalDateState={[operationalDate, setOperationalDate]}
        projectValueState={[projectValue, setProjectValue]}
        installationPriceState={[installationPrice, setInstallationPrice]}
        connectIntegrationState={[connectIntegration, setConnectIntegration]}
        reportAmountState={[reportAmount, setReportAmount]}
        consultingState={[consulting, setConsulting]}
        parameterizedDashState={[parameterizedDash, setParameterizedDash]}
        customDashState={[customDash, setCustomDash]}
        attemptsPerHourState={[attemptsPerHour, setAttemptsPerHour]}
        publicLinkPwdState={[publicLinkPwd, setPublicLinkPwd]}
        scheduledSnapshotAllowedState={[scheduledSnapshotAllowed, setScheduledSnapshotAllowed]}
        maxHistoryState={[maxHistory, setMaxHistory]}
        advancedChartsState={[advancedCharts, setAdvancedCharts]}
        externalKpisState={[externalKpis, setExternalKpis]}
        publicLinkExpirationDaysState={[publicLinkExpirationDays, setPublicLinkExpirationDays]}
        subsetTableLimitState={[subsetTableLimit, setSubsetTableLimit]}
        scheduledHistoryAllowedState={[scheduledHistoryAllowed, setScheduledHistoryAllowed]}
        plans={plans}
        isEdit={isEdit}
      />
    ),
  };

  /**
   * Funcao que checa se o segundo passo pode avancar ou nao
   * @returns booleano que, se verdadeiro, nao possui erros e pode avancar
   */
  const checkSecondForm = () => !(
    contactName.error
    || contactEmail.error
    || contactPhone.error
  );

  /**
   * Segundo passo, informações extras
   */
  const secondStep = {
    label: 'Operacional',
    validation: checkSecondForm,
    content: (
      <SecondStep
        contactNameState={[contactName, setContactName]}
        contactEmailState={[contactEmail, setContactEmail]}
        contactPhoneState={[contactPhone, setContactPhone]}
        obsState={[obs, setObs]}
        trainingState={[training, setTraining]}
        trainingDateState={[trainingDate, setTrainingDate]}
        trainingResponsibleState={[trainingResponsible, setTrainingResponsible]}
      />
    ),
  };

  /**
   * Funcao que checa se o terceiro passo pode avancar ou nao
   * @returns booleano que, se verdadeiro, nao possui erros e pode avancar
   */
  // const checkThirdForm = () => true;

  /**
   * Terceiro passo, emails da instancia
   */
  const thirdStep = {
    label: 'Antecipei',
    validation: () => true,
    content: (
      <ThirdStep
        hasPushState={[hasPush, setHasPush]}
        antecipeiValueState={[antecipeiValue, setAntecipeiValue]}
        numberOfCompaniesState={[numberOfCompanies, setNumberOfCompanies]}
        frequencyState={[frequency, setFrequency]}
        courtsState={[courts, setCourts]}
        weekDayAntecipeiState={[weekDayAntecipei, setWeekDayAntecipei]}
        limitLegalSuitsState={[limitLegalSuits, setLimitLegalSuits]}
        aditionalLegalSuitPriceState={[aditionalLegalSuitPrice, setAditionalLegalSuitPrice]}
      />
    ),
  };

  /**
   * Funcao que checa se o terceiro passo pode avancar ou nao
   * @returns booleano que, se verdadeiro, nao possui erros e pode avancar
   */
  const checkFourthForm = () => adminEmails.length > 0;

  /**
   * Terceiro passo, emails da instancia
   */
  const fourthStep = {
    label: 'Emails',
    validation: checkFourthForm,
    content: (
      <FourthStep
        scientistsEmailsState={[scientistsEmails, setScientistsEmails]}
        adminEmailsState={[adminEmails, setAdminEmails]}
        sendEmailState={[sendEmail, setSendEmail]}
      />
    ),
  };

  /**
   * Lida com o envio dos novos dados para o backend,
   * para criar uma nova instancia ou atualizar uma ja existente
   */
  const handleSubmit = async () => {
    const instanceConfigs = {
      name: companyName.value,
      companyurl: companyUrl.error ? '' : companyUrl.value,
      plan: selectedPlan?.value,
      external_identifier: externalIdentifier.value,
      l1reportActive: createL1Report,
      operationalStatus: operationalStatus.value,
      disabled: false,
      sendmail: sendEmail,
      templates: templates.current,
      support: support.current,
      publicLink,
      multifactor,
      bigData,
      gptApi,
      //
      guests: +guestLimit,
      scientists: +scientistLimit,
      supers: +adminLimit,
      //
      snapshots: +snapsLimit,
      volumeData: +volumeData,
      dashboards: +dashboardLimit,
      // Contrato
      signatureDate,
      operationalDate,
      projectValue,
      installationPrice,
      antecipeiValue,
      connectIntegration: connectIntegration.value,
      reportAmount,
      consulting,
      parameterizedDash,
      customDash,
      // Antecipei
      push: hasPush,
      monitoring: freeMonitoring,
      newMonitoring: monitoringPrice,
      newProcess: newProcessPrice,
      oldProcess: oldProcessPrice,
      numberOfCompanies,
      frequency: frequency?.value,
      courts: courts?.value,
      weekDayAntecipei: weekDayAntecipei?.value,
      limitLegalSuits,
      aditionalLegalSuitPrice,
      // Operacional
      contactName: contactName.value,
      contactEmail: contactEmail.value,
      contactPhone: contactPhone.value,
      obs: obs.value,
      training,
      trainingDate,
      trainingResponsible: trainingResponsible.value,
      attemptsPerHour,
      // Limites de plano
      publicLinkPwd,
      scheduledSnapshotAllowed,
      maxHistory: +maxHistory,
      advancedCharts,
      externalKpis,
      publicLinkExpirationDays: +publicLinkExpirationDays,
      subsetTableLimit: +subsetTableLimit,
      scheduledHistoryAllowed,
    };

    if (isEdit) {
      instanceConfigs.company_id = toEditInstance.id;
      instanceConfigs.disabled = toEditInstance.disabled;
      submitEditInstance(instanceConfigs);
    } else {
      const emailsSuper = adminEmails;
      const emailsScientist = scientistsEmails;
      submitNewInstance(instanceConfigs, emailsSuper, emailsScientist, image.current);
    }
  };

  let steps = [];
  if (process.env.REACT_APP_FIREBASE_PROJECT_LABEL !== 'legalone-analytics') {
    steps = isEdit
      ? [firstStep, secondStep, thirdStep]
      : [firstStep, secondStep, thirdStep, fourthStep];
  } else {
    steps = isEdit ? [firstStep, secondStep] : [firstStep, secondStep, fourthStep];
  }

  return (
    <>
      <StepProgress
        steps={steps}
        hide={closeModal}
        // removeMarkers
        complete={handleSubmit}
        markersWidth="medium"
        disableUntilValid
      />
    </>
  );
};

NewInstance.propTypes = {
  isEdit: PropTypes.bool,
  toEditInstance: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    companyurl: PropTypes.string,
    plan: PropTypes.string,
    external_identifier: PropTypes.string,
    l1reportActive: PropTypes.bool,
    operationalStatus: PropTypes.string,

    templates: PropTypes.arrayOf(PropTypes.shape({})),
    photoUrl: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    support: PropTypes.any,

    volumeData: PropTypes.number,
    dashboards: PropTypes.number,
    snapshots: PropTypes.number,

    guests: PropTypes.number,
    scientists: PropTypes.number,
    superS: PropTypes.number,
    publicLink: PropTypes.bool,
    multifactor: PropTypes.bool,
    gptApi: PropTypes.bool,
    bigData: PropTypes.bool,
    disabled: PropTypes.bool,
    // Contrato
    signatureDate: PropTypes.instanceOf(Date),
    operationalDate: PropTypes.instanceOf(Date),
    projectValue: PropTypes.number,
    installationPrice: PropTypes.number,
    antecipeiValue: PropTypes.number,
    connectIntegration: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.bool,
      errorMsg: PropTypes.string,
    }),
    reportAmount: PropTypes.number,
    consulting: PropTypes.bool,
    parameterizedDash: PropTypes.number,
    customDash: PropTypes.number,
    // Antecipei
    push: PropTypes.bool,
    monitoring: PropTypes.number,
    newMonitoring: PropTypes.number,
    newProcess: PropTypes.number,
    oldProcess: PropTypes.number,
    numberOfCompanies: PropTypes.number,
    frequency: PropTypes.string,
    courts: PropTypes.string,
    weekDayAntecipei: PropTypes.string,
    limitLegalSuits: PropTypes.number,
    aditionalLegalSuitPrice: PropTypes.number,
    // Operacional
    contactName: PropTypes.string,
    contactEmail: PropTypes.string,
    contactPhone: PropTypes.string,
    obs: PropTypes.string,
    training: PropTypes.bool,
    trainingDate: PropTypes.instanceOf(Date),
    trainingResponsible: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.bool,
      errorMsg: PropTypes.string,
    }),
    attemptsPerHour: PropTypes.number,
    // Limites de plano
    publicLinkPwd: PropTypes.bool,
    scheduledSnapshotAllowed: PropTypes.bool,
    maxHistory: PropTypes.number,
    advancedCharts: PropTypes.bool,
    externalKpis: PropTypes.bool,
    publicLinkExpirationDays: PropTypes.number,
    subsetTableLimit: PropTypes.number,
    scheduledHistoryAllowed: PropTypes.bool,
  }),

  closeModal: PropTypes.func,
  submitEditInstance: PropTypes.func,
  submitNewInstance: PropTypes.func,
  plans: PropTypes.shape({}),
};

NewInstance.defaultProps = {
  isEdit: false,
  toEditInstance: {},
  closeModal: () => {},
  submitEditInstance: () => {},
  submitNewInstance: () => {},
  plans: {},
};

export default NewInstance;
