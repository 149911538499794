import React, {
  useRef, useEffect, useState, useContext,
} from 'react';
import { useParams } from 'react-router-dom';

// components
import PublicHeader from '../../components/PublicPage/PublicHeader/PublicHeader';
import PublicKpiToolbar from '../../components/PublicPage/PublicKpiToolbar/PublicKpiToolbar';
import PublicGridKpi from '../../components/PublicPage/PublicGridKpi';
import PublicBlocker from '../../components/PublicPage/PublicBlocker';
import PublicPassword from '../../components/Modals/PublicPassword';
// import PublicFooter from '../../juristec-ui/metricsComponents/PublicPage/PublicFooter';
import Loader from '../../juristec-ui/core/Loader';

// others
import useGridKpi from '../../hooks/useGridKpi';
import useRecaptchaV3 from '../../hooks/useRecaptchaV3';
import { AlertContext } from '../../context/AlertProvider';
import { ModalContext } from '../../context/ModalProvider';

import urlHash from '../../utils/functions/urlHash';

const getParams = (h) => {
  const str = urlHash(h, true).split('/');
  return { dashboardKey: str[0], userId: str[1] };
};

const PublicPage = () => {
  const recaptcha = useRecaptchaV3();
  const isMounted = useRef(false);
  const { hash } = useParams();
  const { dashboardKey, userId } = getParams(hash);

  const { setModalConfig, closeModal } = useContext(ModalContext);
  const { setAlertConfig } = useContext(AlertContext);

  const [reasonBlocker, setReasonBlocker] = useState(null);

  const [gridKpiState, gridKpiAPI] = useGridKpi(userId, dashboardKey);

  const handlePasswordModal = (submitHandler) => {
    setModalConfig({
      title: 'Informe a senha',
      children: (
        <PublicPassword
          submit={submitHandler}
        />
      ),
    });
  };

  const loadPage = async (password = null) => {
    const gToken = await recaptcha.getToken('public');
    const res = await gridKpiAPI.getPublicKpis(gToken, userId, dashboardKey, password);
    if (res.error) {
      switch (res.code) {
        case 'EXPIRED':
          setReasonBlocker('linkExpired');
          closeModal();
          break;
        case 'DASH_NOT_FOUND':
          setReasonBlocker('notFound');
          closeModal();
          break;
        case 'PASSWORD_REQUIRED':
          setReasonBlocker('passwordProtected');
          handlePasswordModal(loadPage);
          break;
        case 'INVALID_PASSWORD': {
          setAlertConfig({
            type: 'error',
            text: 'Acesso não permitido!',
            child: 'Senha inválida. Tente novamente!',
          });
          setReasonBlocker('passwordProtected');
          break;
        }
        case 'CAPTCHA_FAILED':
          setAlertConfig({
            type: 'error',
            text: 'Acesso não permitido!',
            child: 'Foi detectado tráfego incomum em sua rede de computadores. Tente novamente mais tarde!',
          });
          setReasonBlocker('error');
          closeModal();
          break;
        default:
          setReasonBlocker('error');
          closeModal();
          break;
      }
    } else closeModal();
  };

  useEffect(() => {
    if (!isMounted.current) {
      if (!userId || !dashboardKey) return;
      loadPage();
    }
  }, [dashboardKey, gridKpiAPI, userId]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleAction = () => {
    switch (reasonBlocker) {
      case 'passwordProtected':
        handlePasswordModal(loadPage);
        break;
      case 'linkExpired':
        // TODO: ask for new link
        break;
      default:
        break;
    }
  };

  // TODO: modal to request guest access
  // const requestGuestAccess = () => {
  //   setModalConfig({
  //     title: 'Solicite acesso',
  //     children: (
  //       <></>
  //     ),
  //   });
  // };

  return (
    <>
      {(gridKpiState.isLoading || !recaptcha.ready) && <Loader />}
      {(!gridKpiState.isLoading && !gridKpiState.dashboardDoc) ? (
        <PublicBlocker reason={reasonBlocker} action={handleAction} />
      ) : (
        <>
          <PublicHeader dashboardDoc={gridKpiState.dashboardDoc} />
          <PublicKpiToolbar dashboardDoc={gridKpiState.dashboardDoc} />
          <PublicGridKpi
            kpiItemList={gridKpiState.kpiItemList}
            dashboardDoc={gridKpiState.dashboardDoc}
            kpisLayout={gridKpiState.kpisLayout}
          />
        </>
      )}
    </>
  );
};

export default PublicPage;
