/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from '../../../juristec-ui/core/Select';
import Button from '../../../juristec-ui/core/Button';
import Avatar from '../../../juristec-ui/core/Avatar';
import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import PublicLink from '../../PublicLink';

import {
  ShareContainer,
  UserShareRow,
  UserProfile,
  UserInfo,
  UserList,
  UserListItem,
  SelectWrapper,
} from './styled/ShareList.styled';

/**
* A modal to handle dashboard sharing
*/
export const ShareList = ({
  users,
  owner,
  publicLink,
  sharedWith,
  submit,
  close,
  publicUrl,
  publicTimestamp,
  genPublicUrl,
  permissionsOpts,
  permissionsPlan,
  expiresAt,
  passwordProtected,
  getAllInfos,
  tourContext = { tourOpen: false },
}) => {
  const [usersList, setUsersList] = useState([]);
  // const [usersOpts, setUsersOpts] = useState([]);
  const [selected, setSelected] = useState([]);
  const [userOwner, setUserOwner] = useState(null);
  // now
  const [_passwordProtected, _setPasswordProtected] = useState(passwordProtected);

  useEffect(() => {
    const sharedList = [];
    setUsersList(users.reduce((uList, user) => {
      if (user.id !== owner) {
        const fUser = {
          ...user,
          value: user.id,
          label: user.name,
          sharePermission: {
            value: sharedWith?.[user.id]?.value || permissionsOpts[0].value,
          },
        };
        if (sharedWith?.[user.id]) sharedList.push(fUser);
        uList.push(fUser);
      } else {
        setUserOwner(user);
      }
      return uList;
    }, []));
    setSelected(sharedList);
    // setUsersOpts(sharedList);
  }, [users, sharedWith]);

  const handleTempSelecteds = (optns) => setSelected(optns || []);

  const handlePermission = (param, id) => {
    setSelected((old) => old.map((user) => (
      user.value === id ? { ...user, sharePermission: { value: param.value } } : user
    )));
  };

  const handleSubmitData = () => {
    const oldShared = { ...sharedWith };
    selected.forEach((u) => {
      delete oldShared[u.id];
    });
    submit(selected, Object.keys(oldShared));
  };

  useEffect(() => {
    if (tourContext.tourOpen) tourContext.refreshTour();
  }, [selected, tourContext]);

  const _genPublicUrl = async (operation, newPublicUrl, pwd, expDt) => {
    const res = await genPublicUrl(operation, newPublicUrl, pwd, expDt);
    const resInfo = await getAllInfos();
    _setPasswordProtected(resInfo?.passwordProtected);
    return res;
  };

  return (
    <>
      <ShareContainer>
        <UserShareRow>
          <Select
            selectLabel="Compartilhar com"
            placeholder="Selecionar usuário(s)"
            options={usersList}
            fullWidth
            placement="start"
            value={selected}
            isClearable
            onChange={handleTempSelecteds}
            isMult
            atModal
            isSearchable
            alphabeticalOrder
          />
        </UserShareRow>
        <UserList className="list_share_user">
          {userOwner && (
            <UserListItem>
              <UserProfile>
                <Avatar
                  name={userOwner.name || userOwner.email}
                  size="medium"
                  src={userOwner.photoUrl || ''}
                />
                <UserInfo>
                  <span>{userOwner.name || userOwner.email}</span>
                  <span>{userOwner.email}</span>
                </UserInfo>
              </UserProfile>
              <SelectWrapper>
                <span style={{ width: '100px', margin: 'auto' }}>Proprietário</span>
              </SelectWrapper>
            </UserListItem>
          )}
          {selected.map((user) => (
            <UserListItem key={user.value}>
              <UserProfile>
                <Avatar name={user.label || user.email} size="medium" src={user.photoUrl || ''} />
                <UserInfo>
                  <span>{user.label || user.email}</span>
                  <span>{user.email}</span>
                </UserInfo>
              </UserProfile>
              <SelectWrapper>
                {user.role === 'guest' ? (
                  <span style={{ width: '100px', margin: 'auto' }}>Visualização</span>
                ) : (
                  <Select
                    options={permissionsOpts}
                    placement="center"
                    placeholder="Selecione"
                    fullWidth
                    value={user.sharePermission}
                    onChange={(optn) => handlePermission(optn, user.value)}
                    atModal
                    style={{ textAlign: 'center' }}
                  />
                )}
              </SelectWrapper>
            </UserListItem>
          ))}
        </UserList>
      </ShareContainer>
      <hr style={{ width: '75%', margin: '1rem auto 5px' }} />
      <span>{publicLink}</span>
      {publicLink && (
        <PublicLink
          link={publicUrl}
          timestamp={publicTimestamp}
          genPublicUrl={_genPublicUrl}
          permissions={permissionsPlan}
          expiresAt={expiresAt}
          passwordProtected={_passwordProtected}
        />
      )}
      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={close}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button
          style={{ margin: '5px' }}
          onClick={handleSubmitData}
          size="large"
        >
          Concluir
        </Button>
      </ActionsGroup>
    </>
  );
};

ShareList.propTypes = {
  /**
  * Array of users that populates the list
  */
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  /**
  * Dashboard owner's 'user.value'
  */
  owner: PropTypes.string.isRequired,
  publicLink: PropTypes.bool,
  /**
  * Object with users already shared and the type of share
  */
  sharedWith: PropTypes.objectOf(
    PropTypes.shape({
      value: PropTypes.string,
      sharedAt: PropTypes.shape({
        seconds: PropTypes.number,
        nanoseconds: PropTypes.number,
      }),
    }),
  ),
  /**
  * Function to handle the users share options selected
  */
  submit: PropTypes.func.isRequired,
  /**
  * A function that closes the modal
  */
  close: PropTypes.func.isRequired,

  publicUrl: PropTypes.string,
  publicTimestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
  genPublicUrl: PropTypes.func.isRequired,
  permissionsOpts: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  permissionsPlan: PropTypes.shape({}),
  expiresAt: PropTypes.string,
  passwordProtected: PropTypes.bool,
  getAllInfos: PropTypes.func,
  tourContext: PropTypes.shape({
    tourOpen: PropTypes.bool,
    nextStep: PropTypes.func,
  }),
};

ShareList.defaultProps = {
  publicUrl: '',
  publicTimestamp: '',
  users: [],
  publicLink: true,
  sharedWith: {},
  permissionsPlan: false,
  expiresAt: undefined,
  passwordProtected: false,
  getAllInfos: () => {},
  tourContext: { tourOpen: false, nextStep: () => {} },
};

export default ShareList;
