import styled from 'styled-components';
import { gap } from '../../../../juristec-ui/styles/theme';

export const Container = styled.div`
  min-height: 50px;
  min-width: 500px;
  margin: 10px 0px;
`;

export const Infos = styled.div`
  display: flex;
  padding: 5px;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
  ${gap('1.5rem', 'row')};
`;

export const KpiIdContainer = styled.span`
  position: relative;
  cursor: pointer;
  & svg {
    display: none;
    fill: ${({ theme }) => theme.grey};
    position: absolute;
    top: 0;
    right: -22px;
    & > path {
      fill: ${({ theme }) => theme.grey};
    }
  }
  &:hover {
    background-color: ${({ theme }) => theme.copyTextHover};
    border-radius: 5px;
    & svg {
      display: initial;
    }
  }
`;

export const StyledFont = styled.span`
  max-width: max-content;
  font-weight: 500;
  font-size: 16px;
`;

export const KpisCount = styled.div`
  display: flex;
  ${gap('3px', 'row')};
  font-size: 16px;
  max-height: 30px;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
`;

export const FilesList = styled.div`
  border-top: 0.5px solid #C4C4C4;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  & > ::-webkit-scrollbar-track { 
    background: white;
  }
`;

export const EmptyList = styled.div`
  background-color: ${({ theme }) => theme.containerOdd};
  width: 100%;
  text-align: center;
  padding: 10px 5px;
  color: ${({ theme }) => theme.color};
  font-size: 14px;
`;

export const UpdateDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
`;

export const DashboardsTableOverFlow = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 141px);
  box-sizing: border-box;
  padding-bottom: 65px;
`;

export const UpdatedByContainer = styled.span`
  font-size: 9pt;
  font-weight: bold;
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.secondary};
`;

export const ScrollableText = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 150px;
  min-height: 20px;
  overflow: hidden;
  position: relative;

  h4 {
    margin: 0;
    left: 0;
    margin: auto;
    text-align: center;
    white-space: nowrap;
    transform: translateX(0);
    transition: 2.5s;
  }

  &:hover h4 {
    transform: translateX(calc(75px - 100%));
  }

  span {
    font-size: 14px;
  }
`;
