import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// components
import Button from '../../juristec-ui/core/Button';
import Tooltip from '../../juristec-ui/core/Tooltip';
import Modal from '../../juristec-ui/core/Modal';
import PublicLinkCreate from '../Modals/PublicLinkCreate';

// utils
import { formatDateTime } from '../../juristec-ui/utils/functions/lab';
import { Copy, Reload, Close } from '../../juristec-ui/icons';

import {
  Container,
  ContentButton,
  ContentContainer,
  LinkContainer,
  Timestamp,
  Actions,
} from './styled/PublicLink.styled';

function PublicLink({
  link,
  genPublicUrl,
  timestamp,
  expiresAt,
  passwordProtected,
  permissions,
}) {
  const [blockBtn, setBlockBtn] = useState(false);
  const [_link, _setLink] = useState('');
  const [_timestamp, _setTimestamp] = useState(null);
  const [modalOpts, setModalOpts] = useState(false);

  const closeModalOpts = () => setModalOpts(false);
  const openModalCreate = () => setModalOpts('CREATE');
  const openModalUpdate = () => setModalOpts('UPDATE');

  const handleGen = async (operation, newPass = null, expirationDate = null) => {
    setBlockBtn(true);
    const res = await genPublicUrl(operation, _link, newPass, expirationDate);
    setBlockBtn(false);
    if (!res.error) {
      _setLink(res.publicUrl && `${window.location.origin}${res.publicUrl}`);
      _setTimestamp(res.localTimestamp);
    }
  };

  const handleGenLink = async (newPass, expirationDate) => {
    await handleGen('create', newPass, expirationDate);
    closeModalOpts();
  };

  const handleUpdateLink = async (expirationDate) => {
    await handleGen('update', passwordProtected, expirationDate);
    closeModalOpts();
  };

  const revokeLink = async () => {
    await handleGen('delete');
  };

  const handleCpy = () => {
    if (!_link) return;
    //
    const copyText = _link;
    const textArea = document.createElement('textarea');
    textArea.textContent = copyText;
    textArea.style.position = 'fixed'; // Prevent scrolling to bottom of page in MS Edge.
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');

    document.body.removeChild(textArea);

    //
    navigator.clipboard.writeText(_link);
  };

  useEffect(() => {
    if (link) {
      _setLink(`${window.location.origin}${link}`);
      _setTimestamp(timestamp);
    }
  }, [link, timestamp]);

  return (
    <>
      <Container>
        <span className="link-title">
          Link de compartilhamento externo
        </span>
        <ContentContainer>
          {!_link ? (
            <span className="empty-link">
              Não há nenhum link gerado
            </span>
          ) : (
            <>
              <Tooltip
                text="Copiar Link"
                atModal
                direction="top"
              >
                <ContentButton onClick={handleCpy}>
                  <LinkContainer>
                    <span>
                      {_link}
                    </span>
                  </LinkContainer>
                  <Copy />
                </ContentButton>
              </Tooltip>
              {expiresAt && expiresAt !== 'NEVER' && new Date() > new Date(expiresAt) && (
                <span className="expired-link">Este link está expirado!</span>
              )}
            </>
          )}
        </ContentContainer>
        <Actions>
          {_link ? (
            <>
              <Button
                shape="rounded"
                variant="outlined"
                onClick={openModalUpdate}
                disabled={blockBtn}
                className="action-button"
                textTransform="none"
              >
                Atualizar link
                <Reload />
              </Button>
              <Button
                shape="rounded"
                variant="outlined"
                onClick={revokeLink}
                disabled={blockBtn}
                className="action-button"
                textTransform="none"
              >
                Desabilitar link
                <Close />
              </Button>
            </>
          ) : (
            <>
              <Button
                onClick={openModalCreate}
                disabled={blockBtn}
                variant="outlined"
                color="primary"
                shape="rounded"
                className="action-button"
                textTransform="none"
              >
                Gerar link
              </Button>
            </>
          )}
        </Actions>
        {_link && _timestamp && (
          <Timestamp>
            última atualização
            {' '}
            {formatDateTime(_timestamp)}
          </Timestamp>
        )}
      </Container>
      {Boolean(modalOpts) && (
        <Modal
          title="Opções"
          hide={closeModalOpts}
          disableFocus
        >
          <PublicLinkCreate
            isUpdate={modalOpts === 'UPDATE'}
            close={closeModalOpts}
            submit={modalOpts === 'CREATE' ? handleGenLink : handleUpdateLink}
            permissions={{
              daysToExpire: permissions.daysToExpire >= 1000 ? null : permissions.daysToExpire,
              password: permissions.password,
            }}
          />
        </Modal>
      )}
    </>
  );
}

PublicLink.propTypes = {
  link: PropTypes.string,
  genPublicUrl: PropTypes.func,
  timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  permissions: PropTypes.shape({
    daysToExpire: PropTypes.number,
    password: PropTypes.bool,
  }),
  expiresAt: PropTypes.string,
  passwordProtected: PropTypes.bool,
};

PublicLink.defaultProps = {
  link: '',
  genPublicUrl: () => {},
  timestamp: '',
  permissions: {},
  expiresAt: undefined,
  passwordProtected: false,
};

export default PublicLink;
