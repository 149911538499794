import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import Button from '../../../juristec-ui/core/Button';
import TextArea from '../../../juristec-ui/core/TextArea';
import Checkbox from '../../../juristec-ui/core/Checkbox';
import InputTextLine from '../../../juristec-ui/core/InputTextLine';
import UsersCheckList from './UsersCheckList';

import { verifyInput, verifyLink } from '../../../juristec-ui/utils/validators/inputTextValidators';
import { trimString } from '../../../juristec-ui/utils/functions/formatString';

import {
  MainContainer,
  GridContainer,
  UserListContainer,
  UserList,
  TextAreaWrapper,
  CheckboxWrapper,
  OptionGroup,
  Option,
} from './styled/InstanceMessage.styled';
import DatePicker from '../../../juristec-ui/core/DatePicker';

const labelTitle = {
  info: 'AVISO',
  warning: 'ATENÇÃO',
  error: 'ERRO',
};

const InstanceMessage = ({
  hide, submit, users,
}) => {
  const [msg, setMsg] = useState({ error: true, errorMsg: '', value: '' });
  const [title, setTitle] = useState({ error: true, errorMsg: '', value: '' });
  const [link, setLink] = useState({ error: true, errorMsg: '', value: '' });
  const [linkMask, setLinkMask] = useState({ error: true, errorMsg: '', value: '' });
  const [selected, setSelected] = useState({ supers: [], scientists: [], guests: [] });
  const [msgLvl, setMsgLvl] = useState('info');
  const [showUntil, setShowUntil] = useState('');

  const handleMsg = (val) => {
    const errMsg = verifyInput(val, true, 2, 500);
    setMsg({
      value: val,
      error: errMsg.length !== 0,
      errorMsg: errMsg,
    });
  };

  const handleMsgEvent = (e) => {
    const val = e.target.value;
    handleMsg(val);
  };

  const msgTrim = () => {
    handleMsg(trimString(msg.value));
  };

  const handleTitle = (val) => {
    const errMsg = verifyInput(val, false, 0, 50);
    setTitle({
      value: val,
      error: errMsg.length !== 0,
      errorMsg: errMsg,
    });
  };

  const handleTitleEvent = (e) => {
    const val = e.target.value;
    handleTitle(val);
  };

  const titleTrim = () => {
    handleTitle(trimString(title.value));
  };

  const handleLink = (e) => {
    const val = e.target.value?.trim() || '';
    const errMsg = verifyLink(val);
    setLink({
      value: val,
      error: errMsg.length !== 0,
      errorMsg: errMsg,
    });
  };

  const handleLinkMask = (val) => {
    const errMsg = verifyInput(val, false, 0, 50);
    setLinkMask({
      value: val,
      error: errMsg.length !== 0,
      errorMsg: errMsg,
    });
  };

  const handleLinkMaskEvent = (e) => {
    const val = e.target.value;
    handleLinkMask(val);
  };

  const linkMaskTrim = () => {
    handleLinkMask(trimString(linkMask.value));
  };

  const checkAllLvl = (lvl) => selected[lvl].length === users[lvl]?.length;
  const checkAllSupers = () => checkAllLvl('supers');
  const checkAllScientists = () => checkAllLvl('scientists');
  const checkAllGuests = () => checkAllLvl('guests');

  const toggleAll = (check, lvl) => {
    setSelected((o) => ({
      ...o,
      [lvl]: check ? users[lvl]?.map((opt) => opt.uid) : [],
    }));
  };
  const toggleAllSupers = (check) => toggleAll(check, 'supers');
  const toggleAllScientists = (check) => toggleAll(check, 'scientists');
  const toggleAllGuests = (check) => toggleAll(check, 'guests');

  const handleSelection = (check, lvl, key) => {
    setSelected((o) => ({
      ...o,
      [lvl]: check ? [...o[lvl], key] : o[lvl].filter((k) => k !== key),
    }));
  };

  const blockSubmit = () => (
    msg.error
    || msg.value?.length <= 0
    || (
      selected.supers?.length <= 0
      && selected.scientists?.length <= 0
      && selected.guests?.length <= 0
    )
  );
  const showUntilDate = showUntil ? new Date(showUntil.setDate(showUntil.getDate() + 1)) : null;

  const handleSubmit = () => {
    submit(
      msgLvl,
      title.value?.length > 0 && !title.error ? title.value : labelTitle[msgLvl],
      showUntilDate,
      link.value?.length > 0 && !link.error ? {
        [linkMask.value || 'Link externo']:
          link.value.startsWith('https://') ? link.value : `https://${link.value}`,
      } : null,
      [...selected.supers, ...selected.scientists, ...selected.guests],
      msg.value,
    );
  };

  return (
    <MainContainer>
      <GridContainer>
        <UserListContainer>
          <UserList>
            {users.supers?.length > 0 && (
              <CheckboxWrapper>
                <Checkbox
                  text="Todos os proprietários"
                  variant="outlined"
                  style={{ width: '100%', justifyContent: 'flex-start' }}
                  checked={checkAllSupers()}
                  handleCheckboxChange={(check) => toggleAllSupers(check)}
                />
              </CheckboxWrapper>
            )}
            {users.scientists?.length > 0 && (
              <CheckboxWrapper>
                <Checkbox
                  text="Todos os cientistas"
                  variant="outlined"
                  style={{ width: '100%', justifyContent: 'flex-start' }}
                  checked={checkAllScientists()}
                  handleCheckboxChange={(check) => toggleAllScientists(check)}
                />
              </CheckboxWrapper>
            )}
            {users.guests?.length > 0 && (
              <CheckboxWrapper>
                <Checkbox
                  text="Todos os convidados"
                  variant="outlined"
                  style={{ width: '100%', justifyContent: 'flex-start' }}
                  checked={checkAllGuests()}
                  handleCheckboxChange={(check) => toggleAllGuests(check)}
                />
              </CheckboxWrapper>
            )}
            <UsersCheckList
              lvl="supers"
              users={users.supers}
              selected={selected.supers}
              handler={handleSelection}
            />
            <UsersCheckList
              lvl="scientists"
              users={users.scientists}
              selected={selected.scientists}
              handler={handleSelection}
            />
            <UsersCheckList
              lvl="guests"
              users={users.guests}
              selected={selected.guests}
              handler={handleSelection}
            />
          </UserList>
        </UserListContainer>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <OptionGroup>
            <Option
              value="info"
              onClick={() => setMsgLvl('info')}
              active={msgLvl === 'info'}
            >
              Aviso
            </Option>
            <Option
              value="warning"
              onClick={() => setMsgLvl('warning')}
              active={msgLvl === 'warning'}
            >
              Atenção
            </Option>
            <Option
              value="error"
              onClick={() => setMsgLvl('error')}
              active={msgLvl === 'error'}
            >
              Erro
            </Option>
          </OptionGroup>
          <div style={{ margin: '.2rem 0 .5rem' }}>
            <InputTextLine
              label="Título (opcional)"
              error={title.errorMsg.length > 0}
              errorMessage={title.errorMsg}
              value={title.value}
              onChange={handleTitleEvent}
              onBlur={titleTrim}
            />
          </div>
          <div style={{ margin: '.2rem 0 .5rem' }}>
            <DatePicker
              value={showUntil}
              onChange={setShowUntil}
              label="Mensagem disponível até (opcional)"
              style={{ width: '50%' }}
              readOnly={false}
              dateFormat="dd/MM/yyyy"
              atModal
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', margin: '.2rem 0 .5rem' }}>
            <InputTextLine
              label="Link externo (opcional)"
              error={link.errorMsg.length > 0}
              errorMessage={link.errorMsg}
              value={link.value}
              onChange={handleLink}
              wrapperStyle={{ marginRight: '.5rem' }}
            />
            <InputTextLine
              label="Máscara do link (opcional)"
              error={linkMask.errorMsg.length > 0}
              errorMessage={linkMask.errorMsg}
              value={linkMask.value}
              onChange={handleLinkMaskEvent}
              onBlur={linkMaskTrim}
            />
          </div>
          <TextAreaWrapper>
            <TextArea
              label="Mensagem*"
              name="Mensagem"
              value={msg.value}
              error={msg.errorMsg.length > 0}
              errorMessage={msg.errorMsg}
              onChange={handleMsgEvent}
              onBlur={msgTrim}
            />
          </TextAreaWrapper>
        </div>
      </GridContainer>
      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={hide}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button
          style={{ margin: '5px' }}
          size="large"
          disabled={blockSubmit()}
          onClick={handleSubmit}
        >
          Enviar
        </Button>
      </ActionsGroup>
    </MainContainer>
  );
};

InstanceMessage.propTypes = {
  hide: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  users: PropTypes.shape({
    supers: PropTypes.arrayOf(PropTypes.string),
    scientists: PropTypes.arrayOf(PropTypes.string),
    guests: PropTypes.arrayOf(PropTypes.string),
  }),
};

InstanceMessage.defaultProps = {
  users: {
    supers: [],
    scientists: [],
    guests: [],
  },
};

export default InstanceMessage;
