import React from 'react';

const ChartHistogram = ({ primary = '#4711B2', secondary = '#FF9626' }) => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M58.3963 60C44.7217 60 3.6994 60 3.6994 60C1.65959 60 0 58.4208 0 56.4799C0 56.4799 0 15.4725 0 1.60557C0 -0.53519 4.43912 -0.53519 4.43912 1.60557C4.43912 15.2967 4.43912 55.7761 4.43912 55.7761C4.43912 55.7761 44.907 55.7761 58.3962 55.7761C60.5346 55.7761 60.5346 60 58.3963 60Z" fill={primary} />
    <rect width="8" height="15" transform="matrix(1 0 0 -1 7.03999 53.15)" fill={secondary} />
    <rect width="8" height="30" transform="matrix(1 0 0 -1 17.565 53.15)" fill={secondary} />
    <rect width="8" height="50" transform="matrix(1 0 0 -1 28.09 53.15)" fill={secondary} />
    <rect width="8" height="40" transform="matrix(1 0 0 -1 38.615 53.15)" fill={secondary} />
    <rect x="57.14" y="53.15" width="8" height="20" transform="rotate(180 57.14 53.15)" fill={secondary} />
  </svg>
);

export default ChartHistogram;
