import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import Button from '../../../juristec-ui/core/Button';
import InputTextLine from '../../../juristec-ui/core/InputTextLine';
import IconButton from '../../../juristec-ui/core/IconButton';

import { verifyInput } from '../../../juristec-ui/utils/validators/inputTextValidators';
import { compactString } from '../../../juristec-ui/utils/functions/formatString';
import { Visibility, VisibilityOff } from '../../../juristec-ui/icons';

import { MainContainer, TextOptions, InputRow } from './styled/PublicPassword.styled';

const PublicPassword = ({ submit }) => {
  const [password, setPassword] = useState({ value: '', error: true, errorMsg: '' });
  const [showPass, setShowPass] = useState(false);
  const [block, setBlock] = useState(false);

  const togglePass = () => setShowPass((p) => !p);

  const passwordHandle = (e) => {
    const val = compactString(e.target.value);
    const msg = verifyInput(val, true, 3);
    setPassword({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const handleSubmit = async () => {
    setBlock(true);
    await submit(password.value);
    setBlock(false);
  };

  const keyHandle = (e) => {
    if (e.key === 'Enter' && !(password.error || block)) handleSubmit();
  };

  return (
    <MainContainer>
      <InputRow>
        <InputTextLine
          label="Senha"
          type={!showPass ? 'password' : 'text'}
          value={password.value}
          errorMessage={password.errorMsg}
          error={password.errorMsg.length > 0}
          onChange={passwordHandle}
          style={{ paddingRight: '35px' }}
          onKeyDown={keyHandle}
        />
        <IconButton
          style={{
            position: 'absolute',
            bottom: 4,
            right: 2,
            boxShadow: 'none',
            padding: 2,
          }}
          type="button"
          variant="pattern"
          iconColor="black"
          onMouseDown={togglePass}
        >
          {showPass ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputRow>
      <TextOptions>
        Este site é protegido pelo reCAPTCHA e a
        {' '}
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noreferrer"
        >
          Política de Privacidade
        </a>
        {' '}
        e os
        {' '}
        <a
          href="https://policies.google.com/terms"
          target="_blank"
          rel="noreferrer"
        >
          Termos de Serviço
        </a>
        {' '}
        do Google se aplicam.
      </TextOptions>
      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={handleSubmit}
          size="large"
          disabled={password.error || block}
        >
          Enviar
        </Button>
      </ActionsGroup>
    </MainContainer>
  );
};

PublicPassword.propTypes = {
  submit: PropTypes.func.isRequired,
};

export default PublicPassword;
