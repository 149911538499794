import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';
import sha256 from 'crypto-js/sha256';

const stringHasher = (string, unhash) => {
  try {
    if (!unhash) { return Base64.stringify(Utf8.parse(string)); }

    return Base64.parse(string).toString(Utf8);
  } catch (e) {
    window.location = '/home';
  }
};
export default stringHasher;

export const saferStringHasher = (string, unhash) => {
  try {
    if (!unhash) return Base64.stringify(Utf8.parse(string));
    return Base64.parse(string).toString(Utf8);
  } catch (e) {
    return '';
  }
};

export const passwordHasher = (pwd) => sha256(Utf8.parse(pwd)).toString();
