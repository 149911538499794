import styled from 'styled-components';
import { gap } from '../../../../juristec-ui/styles/theme';

export const Container = styled.div`
  min-height: 50px;
  margin: 5px 0px;
`;

export const DashIdContainer = styled.span`
  position: relative;
  cursor: pointer;
  & svg {
    display: none;
    fill: ${({ theme }) => theme.grey};
    position: absolute;
    top: 0;
    right: -22px;
    & > path {
      fill: ${({ theme }) => theme.grey};
    }
  }
  &:hover {
    background-color: ${({ theme }) => theme.copyTextHover};
    border-radius: 5px;
    & svg {
      display: initial;
    }
  }
`;

export const Infos = styled.div`
  display: flex;
  padding: 5px;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
  ${gap('1.5rem', 'row')};
`;

export const StyledFont = styled.span`
  max-width: max-content;
  font-weight: 500;
  font-size: 16px;
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.secondary};
`;

export const HistoryCount = styled.div`
  display: flex;
  ${gap('3px', 'row')};
  font-size: 16px;
  max-height: 30px;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
`;

export const DashboardsList = styled.div`
  border-top: 0.5px solid #C4C4C4;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  & > ::-webkit-scrollbar-track { 
    background: white;
  }
`;

export const EmptyList = styled.div`
  background-color: ${({ theme }) => theme.containerOdd};
  width: 100%;
  text-align: center;
  padding: 10px 5px;
  color: ${({ theme }) => theme.color};
  font-size: 14px;
`;

export const DashboardsTableOverFlow = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 141px);
  box-sizing: border-box;
  padding-bottom: 65px;
`;

export const Options = styled.div`
  display: flex;
  justify-content: center;
  ${gap('15px', 'row')}
  align-items: center;
`;

export const UserDashText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & h4 {
    margin: 0;
  }
  & span {
    font-size: 14px;
  }
`;
