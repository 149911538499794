import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TextOptions = styled.span`
  display: block;
  padding-top: 15px;
  font-size: 0.6rem;
  color: ${({ theme }) => theme.color};
  max-width: 300px;
  & > a, span {
    cursor: pointer;
    color: ${({ theme }) => theme.primary};
    font-weight: bold;
    text-decoration-line: none;
  }
`;

export const InputRow = styled.div`
  position: relative;
  width: 100%;
`;
