import styled from 'styled-components';
import { gap } from '../../../../juristec-ui/styles/theme';

export const Container = styled.div`
  padding: 20px;
  border-radius: 8px;
  width: 50vw;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  ${gap('10px', 'column')};
`;

export const Title = styled.div`
  display: block;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
`;

export const InfoContainer = styled.div`
  display: flex;
  ${gap('3px', 'row')};
  width: 100%;
  max-width: 100%;
`;

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ${gap('10px', 'row')};
  margin-bottom: 5px;
`;

export const TextContainer = styled.h4`
  position: relative;
  cursor: pointer;
  & svg {
    display: none;
    fill: ${({ theme }) => theme.grey};
    position: absolute;
    top: 0;
    right: -22px;
    & > path {
      fill: ${({ theme }) => theme.grey};
    }
  }
  &:hover {
    background-color: ${({ theme }) => theme.copyTextHover};
    border-radius: 5px;
    & svg {
      display: initial;
    }
  }
`;

export const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;

  h4 {
    margin: 0;
    left: 0;
    text-align: center;
    white-space: nowrap;
  }

  span {
    font-size: 14px;
  }
`;
