import React from 'react';
import PropTypes from 'prop-types';

const AllowExpand = ({ height, width }) => (
  <svg width={width} height={height} viewBox="0 0 40 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M40 11.6667H36.6667V3.33333H25V0H40V11.6667ZM0 21.6667H3.33333V30H15V33.3333H0V21.6667ZM15 0V3.33333H3.33333V11.6667H0V0H15ZM33.3333 6.66667H6.66667V26.6667H26L26.5 23.6667L28.1667 21.3333L30.3333 19.8333L33.3333 19.3333V6.66667Z" />
    <path d="M33.3337 33.3333C35.1018 33.3333 36.7975 32.631 38.0477 31.3807C39.2979 30.1305 40.0003 28.4348 40.0003 26.6667C40.0003 24.8986 39.2979 23.2029 38.0477 21.9526C36.7975 20.7024 35.1018 20 33.3337 20C31.5655 20 29.8699 20.7024 28.6196 21.9526C27.3694 23.2029 26.667 24.8986 26.667 26.6667C26.667 28.4348 27.3694 30.1305 28.6196 31.3807C29.8699 32.631 31.5655 33.3333 33.3337 33.3333ZM36.2764 25.4427L32.943 28.776C32.6982 29.0208 32.3024 29.0208 32.0602 28.776L30.3936 27.1094C30.1488 26.8646 30.1488 26.4688 30.3936 26.2266C30.6383 25.9844 31.0342 25.9818 31.2764 26.2266L32.5003 27.4505L35.391 24.5573C35.6357 24.3125 36.0316 24.3125 36.2738 24.5573C36.516 24.8021 36.5186 25.1979 36.2738 25.4401L36.2764 25.4427Z" />
  </svg>
);

AllowExpand.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

AllowExpand.defaultProps = {
  height: '40',
  width: '34',
};

export default AllowExpand;
