/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

import DataTable from '../../../juristec-ui/core/DataTable';
import { formatDateTime } from '../../../juristec-ui/utils/functions/lab';
import Tooltip from '../../../juristec-ui/core/Tooltip';
import Badge from '../../../juristec-ui/core/Badge';
import DataCards from '../../../juristec-ui/core/DataCards';
import IconButton from '../../../juristec-ui/core/IconButton';

import {
  UserConfig,
  UserAtom,
  Users,
  Question,
  ProfileUser,
  Trash,
  UserReactivate,
  TransferUser,
  CloneDash,
  Edit,
  NewFile,
  ClosedLock,
  OpenLock,
  Sessions,
  MoreVert,
  Finder,
  DateIcon,
} from '../../../juristec-ui/icons';

import useDeviceType from '../../../juristec-ui/hooks/useDeviceType';

import {
  Options,
  BadgeContent,
  IconWrapper,
  UserInfos,
  InputWrapper,
  OverflowControl,
  Container,
} from './styled/UsersTable.styled';
import Avatar from '../../../juristec-ui/core/Avatar';
import Popover from '../../../juristec-ui/core/Popover';
import List from '../../../juristec-ui/core/List';
import ListItem from '../../../juristec-ui/core/ListItem';
import InputTextLine from '../../../juristec-ui/core/InputTextLine';

/**
 * A component that displays the users info and options
 */
const InstanceUsersTable = ({
  users,
  handleTransfer,
  handleBlock,
  handleDelete,
  handleReactivateUser,
  handlePermission,
  handleCopyDashboards,
  handleMultiSession,
  handleGenEmptyGed,
  userIsAdmin,
  handleRegisterTrainingDate,
}) => {
  const device = useDeviceType();
  const theme = useTheme();

  const [data, setData] = useState(users);
  const [filterText, setFilterText] = useState('');

  const DataListingComponent = device === 'phone' ? DataCards : DataTable;
  useEffect(() => {
    const value = filterText.toLowerCase();
    setData(users.filter((user) => (
      user?.displayName && user?.displayName.toLowerCase().includes(value))
      || (user?.email && user?.email.toLowerCase().includes(value))));
  }, [users, filterText]);

  useEffect(() => {
    setData(users);
  }, [users]);

  const UserExtraOptions = ({ user }) => {
    const [showOptions, setShowOptions] = useState(false);
    const _handleBlock = () => {
      const { modalHandler, handler } = handleBlock(user);

      const aux = (d) => {
        setData((p) => (
          p.map((u) => (u.uid === user.uid ? { ...u, disabled: d } : u))
        ));
      };

      const handlerWrapper = () => handler(aux);
      modalHandler(handlerWrapper);
    };

    const _handleRegisterTrainingDate = () => {
      handleRegisterTrainingDate(user, setData);
    };

    const _handlePermission = () => {
      handlePermission(user);
    };
    const _lockMultiSession = () => {
      handleMultiSession(user, 'False');
      setData((p) => (
        p.map((u) => (u.uid === user.uid ? { ...u, multiSessions: false } : u))
      ));
    };

    const _unlockMultiSession = () => {
      handleMultiSession(user, 'True');
      setData((p) => (
        p.map((u) => (u.uid === user.uid ? { ...u, multiSessions: true } : u))
      ));
    };

    const _handleCopyDashboards = () => {
      handleCopyDashboards(user);
    };

    return (
      <Popover closePopover={() => setShowOptions(false)} open={showOptions}>
        <Popover.Action>
          <Tooltip text="Mais opções">
            <IconButton variant="pattern" shape="rounded" onClick={() => setShowOptions(true)}>
              <MoreVert aria-hidden="true" type="button" data-dismiss="modal" aria-label="Close" />
            </IconButton>
          </Tooltip>
        </Popover.Action>
        <Popover.Content style={{ zIndex: 1050 }}>
          <List>
            <ListItem onClick={() => _handleRegisterTrainingDate()} disabled={user?.removed}>
              <DateIcon />
              Cadastrar data de treinamento
            </ListItem>
            { userIsAdmin && (
              <ListItem onClick={_handleBlock} disabled={user?.removed}>
                {user.disabled ? (
                  <>
                    <OpenLock width="24" height="24" />
                    Desbloquear usuário
                  </>
                ) : (
                  <>
                    <ClosedLock />
                    Bloquear usuário
                  </>
                )}
              </ListItem>
            )}
            <ListItem onClick={_handlePermission} disabled={user?.removed}>
              <Edit />
              Alterar permissão
            </ListItem>
            <ListItem
              onClick={user.multiSessions ? _lockMultiSession : _unlockMultiSession}
              disabled={user?.removed}
            >
              {user.multiSessions ? (
                <>
                  <ProfileUser />
                  Bloquear sessão simultânea
                </>
              ) : (
                <>
                  <Sessions width="24" height="24" />
                  Liberar sessão simultânea
                </>
              )}
            </ListItem>
            {!!handleTransfer && (
              <ListItem onClick={_handleCopyDashboards} disabled={user?.removed}>
                <CloneDash />
                Copiar arquivos e dashboards para suporte
              </ListItem>
            )}
          </List>
        </Popover.Content>
      </Popover>
    );
  };
  UserExtraOptions.propTypes = {
    user: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
      disabled: PropTypes.bool,
      multiSessions: PropTypes.bool,
      role: PropTypes.string,
      removed: PropTypes.bool,
      removedByEmail: PropTypes.string,
      removedAt: PropTypes.string,
      uid: PropTypes.string,
    }).isRequired,
  };

  const UserOptions = ({ user }) => {
    const _handleTransfer = () => {
      handleTransfer(user);
    };

    const _handleDelete = () => {
      handleDelete(user);
    };

    const _handleReactivateUser = () => {
      const { handler, modalHandler } = handleReactivateUser(user);

      const aux = (d) => {
        setData((p) => p.map((u) => (u.uid === user.uid ? { ...u, removed: d } : u)));
      };

      const handlerWrapper = () => handler(aux);
      modalHandler(handlerWrapper);
    };

    const _handleEmptyGed = () => {
      handleGenEmptyGed(user);
    };

    return (
      <Options>
        {process.env.REACT_APP_FIREBASE_PROJECT_LABEL === 'legalone-analytics' && (
          <Tooltip text="Gerar relatório GED vazio" atModal>
            <IconButton variant="pattern" color="primary" onClick={_handleEmptyGed} disabled={user?.removed || user?.role === 'guest'}>
              <NewFile />
            </IconButton>
          </Tooltip>
        )}
        {userIsAdmin && (
          <>
            {!!handleTransfer && (
              <Tooltip text="Transferir arquivos e copiar dashboards" atModal>
                <IconButton variant="pattern" color="primary" onClick={_handleTransfer} disabled={user?.removed}>
                  <TransferUser />
                </IconButton>
              </Tooltip>
            )}
            {user.removed ? (
              <Tooltip text="Reativar usuário" atModal>
                <IconButton variant="pattern" color="primary" onClick={_handleReactivateUser}>
                  <UserReactivate />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip text="Apagar usuário" atModal>
                <IconButton variant="pattern" color="primary" onClick={_handleDelete}>
                  <Trash />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
      </Options>
    );
  };
  UserOptions.propTypes = {
    user: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
      disabled: PropTypes.bool,
      multiSessions: PropTypes.bool,
      role: PropTypes.string,
      removed: PropTypes.bool,
      removedByEmail: PropTypes.string,
      removedAt: PropTypes.string,
      uid: PropTypes.string,
    }).isRequired,
  };

  /* eslint-disable react/destructuring-assignment */
  const UserInfo = (user) => (
    <UserInfos>
      <Avatar name={user.name} />
      <div className="instance-row-user-name-email">
        <span className="name">
          {user.name}
        </span>
        <span className="email">
          {user.email}
        </span>
        {user.removed && (
          <>
            <span className="removed">
              <strong style={{ color: theme.error }}>Removido por: </strong>
              <span>{user?.removedByEmail || 'Usuário não encontrado'}</span>
            </span>
            <span className="removed">
              <strong style={{ color: theme.error }}>Removido em: </strong>
              <span>{user?.removedAt || 'Data não encontrada'}</span>
            </span>
          </>
        )}
      </div>
    </UserInfos>
  );

  UserInfo.propTypes = {
    user: PropTypes.shape({
      displayName: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
      removed: PropTypes.bool,
      removedByEmail: PropTypes.string,
      removedAt: PropTypes.string,
    }).isRequired,
  };

  const formatDate = (datetime) => (
    datetime ? formatDateTime(datetime, { time: 'none' }) : 'Não consta'
  );

  const formatRole = (role) => {
    let conditionalColor = 'error';
    let icon = <Question height="20px" />;
    let userType = 'Desconhecido';
    switch (role) {
      case 'super':
        conditionalColor = 'primary';
        icon = <UserConfig width="20px" />;
        userType = 'Proprietário';
        break;
      case 'scientist':
        conditionalColor = 'success';
        icon = <UserAtom width="20px" />;
        userType = 'Cientista';
        break;
      case 'guest':
        conditionalColor = 'warning';
        icon = <Users width="20px" />;
        userType = 'Convidado';
        break;
      default:
        break;
    }
    return (
      <Badge color={conditionalColor} style={{ margin: 'auto' }} size="small">
        <BadgeContent>
          <IconWrapper color={conditionalColor}>
            {icon}
          </IconWrapper>
          {userType}
        </BadgeContent>
      </Badge>
    );
  };
  const cronRef = useRef();
  const handleFilter = (e) => {
    const { value } = e.target;
    clearTimeout(cronRef.current);
    cronRef.current = setTimeout(() => {
      setFilterText(value);
    }, 150);
  };
  const columns = [
    {
      field: 'email',
      role: 'primaryInfo',
      label: 'Usuário',
      sortable: true,
      valueGetter: (param) => UserInfo(param),
    },
    {
      field: 'role',
      role: 'extraInfo',
      label: 'Permissão',
      sortable: true,
      valueGetter: (param) => formatRole(param?.role),
    },
    {
      field: 'trainedAt',
      role: 'extraInfo',
      label: 'Treinamento',
      sortable: true,
      valueGetter: (param) => formatDate(param?.trainedAt),
    },
    {
      field: 'options',
      role: 'optionsBtn',
      label: 'Opções',
      valueGetter: (param) => <UserOptions user={param} />,
    },
    {
      field: 'extraOptions',
      role: 'extraOptions',
      label: '',
      valueGetter: (param) => (
        userIsAdmin && (
          <Tooltip text="Mais opções" atModal>
            <UserExtraOptions user={param} />
          </Tooltip>
        )
      ),
    },
  ];

  return (
    <Container>
      <hr className="divider" />
      <InputWrapper>
        <InputTextLine
          placeholder="Digite o nome ou e-mail para buscar"
          onChange={handleFilter}
        />
        <Finder />
      </InputWrapper>
      <OverflowControl>
        <DataListingComponent
          columns={columns}
          rowData={data}
          strippedRowsColor={theme.containerOdd}
          defaultSortField="email"
          defaultSortOrder="ascending"
          rowColor={theme.tableBackground}
          theadStyle={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: theme.background,
          }}
        />
      </OverflowControl>
    </Container>
  );
};

InstanceUsersTable.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({
    uid: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    disabled: PropTypes.bool,
    multiSessions: PropTypes.bool,
    role: PropTypes.string,
    removed: PropTypes.bool,
    removedByEmail: PropTypes.string,
    removedAt: PropTypes.string,
  })),
  handleTransfer: PropTypes.func,
  handleBlock: PropTypes.func,
  handleDelete: PropTypes.func,
  handleReactivateUser: PropTypes.func,
  handlePermission: PropTypes.func,
  handleCopyDashboards: PropTypes.func,
  handleMultiSession: PropTypes.func,
  handleGenEmptyGed: PropTypes.func,
  userIsAdmin: PropTypes.bool,
  handleRegisterTrainingDate: PropTypes.func,
};

InstanceUsersTable.defaultProps = {
  users: [],
  handleTransfer: () => {},
  handleBlock: () => {},
  handleDelete: () => {},
  handleReactivateUser: () => {},
  handlePermission: () => {},
  handleCopyDashboards: () => {},
  handleMultiSession: () => {},
  handleGenEmptyGed: () => {},
  handleRegisterTrainingDate: () => {},
  userIsAdmin: false,
};

export default InstanceUsersTable;
