import React from 'react';

const ChartPareto = ({ primary = '#4711B2', secondary = '#FF9626' }) => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M58.3963 60C44.7217 60 3.6994 60 3.6994 60C1.65959 60 0 58.4208 0 56.4799C0 56.4799 0 15.4725 0 1.60557C0 -0.53519 4.43912 -0.53519 4.43912 1.60557C4.43912 15.2967 4.43912 55.7761 4.43912 55.7761C4.43912 55.7761 44.907 55.7761 58.3962 55.7761C60.5346 55.7761 60.5346 60 58.3963 60Z" fill={primary} />
    <path d="M42.0412 39.5156C41.4308 39.5156 40.9313 39.6715 40.9313 39.862V52.1891C40.9313 52.3796 41.4308 52.5356 42.0412 52.5356H56.079C56.6894 52.5356 57.1889 52.3796 57.1889 52.1891V39.862C57.1889 39.6715 56.6894 39.5156 56.079 39.5156H42.0412Z" fill={primary} />
    <path d="M24.9882 33.4499C24.3778 33.4499 23.8781 33.6428 23.8781 33.8785V52.5546C23.8781 52.7903 24.3778 52.9831 24.9882 52.9831H36.4559C37.0663 52.9831 37.5658 52.7903 37.5658 52.5546V33.8783C37.5658 33.6426 37.0663 33.4498 36.4559 33.4498L24.9882 33.4499Z" fill={primary} />
    <path d="M20.6349 53.1452C21.2453 53.1452 21.7447 52.67 21.7447 52.0893V24.9041C21.7447 24.3234 21.2453 23.8481 20.6349 23.8481H8.14935C7.53897 23.8481 7.03945 24.3234 7.03945 24.9041V52.0896C7.03945 52.6703 7.53897 53.1455 8.14935 53.1455L20.6349 53.1452Z" fill={primary} />
    <path d="M18.5 24.031C18.5 26.2401 16.7091 28.031 14.5 28.031C12.2909 28.031 10.5 26.2401 10.5 24.031C10.5 21.8218 12.2909 20.031 14.5 20.031C16.7091 20.031 18.5 21.8218 18.5 24.031Z" fill={secondary} />
    <path d="M18.5 24.031C18.5 26.2401 16.7091 28.031 14.5 28.031C12.2909 28.031 10.5 26.2401 10.5 24.031C10.5 21.8218 12.2909 20.031 14.5 20.031C16.7091 20.031 18.5 21.8218 18.5 24.031Z" fill={secondary} />
    <path d="M35 12C35 14.2091 33.2091 16 31 16C28.7909 16 27 14.2091 27 12C27 9.79086 28.7909 8 31 8C33.2091 8 35 9.79086 35 12Z" fill={secondary} />
    <path d="M35 12C35 14.2091 33.2091 16 31 16C28.7909 16 27 14.2091 27 12C27 9.79086 28.7909 8 31 8C33.2091 8 35 9.79086 35 12Z" fill={secondary} />
    <path d="M53 5C53 7.20914 51.2091 9 49 9C46.7909 9 45 7.20914 45 5C45 2.79086 46.7909 1 49 1C51.2091 1 53 2.79086 53 5Z" fill={secondary} />
    <path d="M53 5C53 7.20914 51.2091 9 49 9C46.7909 9 45 7.20914 45 5C45 2.79086 46.7909 1 49 1C51.2091 1 53 2.79086 53 5Z" fill={secondary} />
    <path fillRule="evenodd" clipRule="evenodd" d="M31.4833 12.885L49.3624 5.932L48.6376 4.068L30.5167 11.115L13.9118 23.1913L15.0882 24.8087L31.4833 12.885Z" fill={secondary} />
  </svg>
);

export default ChartPareto;
