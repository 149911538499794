import React from 'react';
import PropTypes from 'prop-types';

const Sessions = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 156 155" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M101.791 112.306L80.4523 99.9874V75.3494C80.4523 73.7185 79.1314 72.3977 77.5006 72.3977C75.8719 72.3977 74.5511 73.7185 74.5511 75.3494V99.9874L53.2119 112.306C51.8012 113.122 51.3153 114.929 52.1306 116.339C52.9461 117.751 54.7525 118.234 56.1636 117.421L77.5003 105.099L98.8394 117.418C100.25 118.234 102.055 117.75 102.87 116.339C103.686 114.929 103.202 113.122 101.791 112.306Z" />
    <path d="M125.5 86C115.468 86 107.307 94.161 107.307 104.193C107.307 114.226 115.468 122.387 125.5 122.387C135.532 122.387 143.693 114.226 143.693 104.193C143.693 94.161 135.532 86 125.5 86Z" />
    <path d="M155.483 139.728C150.044 131.569 140.941 126.699 131.138 126.699H119.862C110.058 126.699 100.956 131.569 95.5172 139.728L95.1776 140.238V155H155.822L155.822 140.238L155.483 139.728Z" />
    <path d="M77.4999 0C67.4675 0 59.3065 8.161 59.3065 18.1934C59.3065 28.2257 67.4675 36.3867 77.4999 36.3867C87.5322 36.3867 95.6932 28.2257 95.6932 18.1934C95.6932 8.161 87.5322 0 77.4999 0Z" />
    <path d="M107.483 53.7284C102.044 45.5694 92.9413 40.6992 83.1379 40.6992H71.8618C62.0584 40.6992 52.9558 45.5694 47.5172 53.7284L47.1776 54.2376V69H107.822L107.822 54.2376L107.483 53.7284Z" />
    <path d="M30.4999 86C20.4675 86 12.3065 94.161 12.3065 104.193C12.3065 114.226 20.4675 122.387 30.4999 122.387C40.5322 122.387 48.6932 114.226 48.6932 104.193C48.6932 94.161 40.5322 86 30.4999 86Z" />
    <path d="M60.4827 139.728C55.0441 131.569 45.9413 126.699 36.1379 126.699H24.8618C15.0584 126.699 5.95581 131.569 0.517209 139.728L0.1776 140.238V155H60.8221L60.8223 140.238L60.4827 139.728Z" />
  </svg>
);

Sessions.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

Sessions.defaultProps = {
  width: '156',
  height: '156',
};

export default Sessions;
