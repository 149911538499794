/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import InputTextLine from '../InputTextLine';

import { DatePickerContainer, IconWrapper, DatePickerWrapper } from './styled/DatePicker.styled';

import 'react-datepicker/dist/react-datepicker.css';
import { Date as DateIcon } from '../../icons';

const DatePicker = ({
  value,
  label,
  startDate,
  endDate,
  minDate,
  maxDate,
  onChange,
  dateFormat,
  readOnly,
  isClearable,
  onlyYears,
  stayOpen,
  atModal,
}) => {
  const TextInputDate = forwardRef(
    ({
      value, label, onClick, onChange,
    }, ref) => (
      <div style={{ position: 'relative', width: '100%' }}>
        <InputTextLine
          value={value}
          onClick={onClick}
          ref={ref}
          label={label}
          onChange={onChange}
          readOnly={readOnly}
          fullWidth
          style={{ paddingRight: '35px', cursor: 'pointer' }}
        />
        <IconWrapper>
          <DateIcon />
        </IconWrapper>
      </div>
    ),
  );

  return (
    <DatePickerContainer>
      <ReactDatePicker
        selected={value}
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
        maxDate={maxDate}
        onChange={(date) => onChange(date)}
        customInput={<TextInputDate label={label} onChange={onChange} />}
        locale={ptBR}
        shouldCloseOnSelect={!stayOpen}
        dateFormat={onlyYears ? 'yyyy' : dateFormat}
        wrapperClassName="datepicker-style"
        popperClassName={atModal ? 'container-style-at-modal' : 'container-style'}
        popperContainer={DatePickerWrapper}
        dayClassName={() => 'day-style'}
        monthClassName={() => 'month-style'}
        isClearable={isClearable}
        showYearPicker={onlyYears}
        portalId="portals"
      />
    </DatePickerContainer>
  );
};

DatePicker.propTypes = {
  value: PropTypes.instanceOf(Date),
  label: PropTypes.string,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  onlyYears: PropTypes.bool,
  isClearable: PropTypes.bool,
  dateFormat: PropTypes.string,
  readOnly: PropTypes.bool,
  atModal: PropTypes.bool,
  stayOpen: PropTypes.bool,
};

DatePicker.defaultProps = {
  value: new Date(),
  label: '',
  startDate: null,
  endDate: null,
  minDate: null,
  maxDate: null,
  onChange: () => {},
  onlyYears: false,
  isClearable: false,
  dateFormat: 'yyyy-MM-dd',
  readOnly: true,
  atModal: false,
  stayOpen: true,
};

export default DatePicker;
