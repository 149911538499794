import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

// components
import DataTable from '../../../juristec-ui/core/DataTable';
import TablePagination from '../../../juristec-ui/core/TablePagination';

// styled
import {
  Container,
  Infos,
  StyledFont,
  FilesList,
  KpisCount,
  EmptyList,
  Title,
  ScrollableText,
  KpiIdContainer,
} from './styled/DashboardKpisList.styled';

// utils
import { formatDateTime } from '../../../juristec-ui/utils/functions/lab';
import { charts } from '../../../options';
import Tooltip from '../../../juristec-ui/core/Tooltip';

const copyToClipboard = (copyText) => {
  navigator.clipboard.writeText(copyText).catch((err) => {
    console.error('Falha ao copiar: ', err);
  });
};

function DashboardKpisList({
  dashboard,
  kpis,
}) {
  const [page, setPage] = useState(0);
  const [copied, setCopied] = useState(false);

  const theme = useTheme();

  const getKpiName = (param) => {
    if (param?.name) {
      return param.name;
    }
    switch (param.type) {
      case 'Iframe':
        return 'KPI de conteúdo externo';
      default:
        return 'KPI sem nome';
    }
  };
  const columns = [
    {
      field: 'name',
      label: 'Título',
      sortable: true,
      valueGetter: (param) => (
        <ScrollableText>
          <h4>{getKpiName(param)}</h4>
          <Tooltip text={copied ? 'Copiado!' : 'Clique para copiar'} atModal>
            <KpiIdContainer
              onClick={() => {
                copyToClipboard(param?.id || '');
                setCopied(true);
              }}
              onMouseEnter={() => setCopied(false)}
            >
              {param?.id || 'Id não encontrado'}
            </KpiIdContainer>
          </Tooltip>
        </ScrollableText>
      ),
    },
    {
      field: 'type',
      label: 'Tipo',
      sortable: true,
      valueGetter: (param) => charts[param.type]?.value ?? 'Desconhecido',
    },
    {
      field: 'createdAt',
      label: 'Criado em',
      valueGetter: (param) => (param.createdAt ? formatDateTime(param.createdAt, { time: 'half' }) : '-'),
      sortable: true,
    },
    {
      field: 'updatedAt',
      label: 'Atualizado em',
      valueGetter: (param) => (param.updatedAt ? formatDateTime(param.updatedAt, { time: 'half' }) : '-'),
      sortable: true,
    },
  ];

  return (
    <Container>
      <Infos>
        <StyledFont>
          <Title>Dashboard: </Title>
          {' '}
          {dashboard?.name || ''}
        </StyledFont>
        <KpisCount>
          <StyledFont>
            <Title>KPIs: </Title>
            {' '}
            {kpis.length}
          </StyledFont>
        </KpisCount>
      </Infos>
      <FilesList>
        {kpis.length > 0 ? (
          <>
            <DataTable
              columns={columns}
              rowData={kpis}
              rowColor={theme.tableBackground}
              strippedRowsColor={theme.containerOdd}
              defaultSortField="name"
              headerColor="transparent"
              theadStyle={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                backgroundColor: theme.background,
              }}
              tbodyStyle={{
                fontSize: '14px',
              }}
              usePagination
              page={page}
              itemsPerPage={5}
            />
            <TablePagination
              page={page}
              setPage={setPage}
              totalPages={
                Math.floor(kpis.length % 5 === 0
                  ? (kpis.length / 5) - 1
                  : kpis.length / 5)
              }
            />
          </>
        ) : (
          <EmptyList>
            Este dashboard não possui nenhum KPI.
          </EmptyList>
        )}
      </FilesList>
    </Container>
  );
}

DashboardKpisList.propTypes = {
  /**
   * Object with the dashboard data.
  * */
  dashboard: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  /**
   * Array of the files data.
  * */
  kpis: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      type: PropTypes.string,
    }),
  ).isRequired,
};

DashboardKpisList.defaultProps = {};

export default DashboardKpisList;
