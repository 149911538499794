import React from 'react';
import PropTypes from 'prop-types';

const BlockExpand = ({ height, width }) => (
  <svg width={width} height={height} viewBox="0 0 40 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M40 11.6667H36.6667V3.33333H25V0H40V11.6667ZM0 21.6667H3.33333V30H15V33.3333H0V21.6667ZM15 0V3.33333H3.33333V11.6667H0V0H15ZM33.3333 6.66667H6.66667V26.6667H26L26.5 23.6667L28.1667 21.3333L30.3333 19.8333L33.3333 19.3333V6.66667Z" />
    <path d="M36.2295 30.7422L29.2581 23.7708C28.6748 24.5859 28.3337 25.5859 28.3337 26.6667C28.3337 29.4271 30.5732 31.6667 33.3337 31.6667C34.4144 31.6667 35.4144 31.3255 36.2295 30.7422ZM37.4092 29.5625C37.9925 28.7474 38.3337 27.7474 38.3337 26.6667C38.3337 23.9062 36.0941 21.6667 33.3337 21.6667C32.2529 21.6667 31.2529 22.0078 30.4378 22.5911L37.4092 29.5625ZM26.667 26.6667C26.667 24.8986 27.3694 23.2029 28.6196 21.9526C29.8699 20.7024 31.5655 20 33.3337 20C35.1018 20 36.7975 20.7024 38.0477 21.9526C39.2979 23.2029 40.0003 24.8986 40.0003 26.6667C40.0003 28.4348 39.2979 30.1305 38.0477 31.3807C36.7975 32.631 35.1018 33.3333 33.3337 33.3333C31.5655 33.3333 29.8699 32.631 28.6196 31.3807C27.3694 30.1305 26.667 28.4348 26.667 26.6667Z" />
  </svg>
);

BlockExpand.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

BlockExpand.defaultProps = {
  height: '40',
  width: '34',
};

export default BlockExpand;
